import { createSelector } from '@reduxjs/toolkit';
import { ApplicationState } from '..';
import { IssuesState } from './issues.reducer';

const issuesState = (state: ApplicationState): IssuesState => state.issues;

export const issuesSelector = createSelector(
  issuesState,
  (state) => state.issues
);

export const selectedIssueIdSelector = createSelector(
  issuesState,
  (state) => state.selectedIssueId
);

export const issueConfigSelector = createSelector(
  issuesState,
  (state) => state.issueConfig
);

export const issuesStatusSelector = createSelector(
  issuesState,
  (state) => state.status
);

export const isIssuesLoadingSelector = createSelector(
  issuesStatusSelector,
  (status) => status == 'loading'
);

export const isIssuesSavingSelector = createSelector(
  issuesStatusSelector,
  (status) => status == 'saving'
);

export const issueTypesSelector = createSelector(
  issueConfigSelector,
  (issueConfig) => issueConfig.issueTypes
);

export const contributingFactorsSelector = createSelector(
  issueConfigSelector,
  (issueConfig) => issueConfig.contributorFactors
);

export const selectedIssueSelector = createSelector(
  issuesSelector,
  selectedIssueIdSelector,
  (issues, selectedId) => issues.find((x) => x.id == selectedId)
);
