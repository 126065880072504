import * as React from 'react';
import * as ProjectStore from '../store/projects';
import { ApplicationState } from '../store';
import { connect, useSelector } from 'react-redux';
import { ListItem, ProjectVm, ContactCard } from '../interfaces/interfaces';
import { check_money_format } from '../helpers/formatters';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import FilterableSelect from '../components/FilterableSelect';
import { JoinedList } from '../components/JoinedList';
import Modal from '../components/Modal';
import { actionCreators as ProjectActions } from '../store/projects';
import { useDispatch } from 'react-redux';

interface FormProps {
  close: () => any;
}

type Props = FormProps;

export const NewProjectForm = (props: Props) => {
  const [clients, setClients] = React.useState([] as ListItem[]);
  const [brands, setBrands] = React.useState([] as ListItem[]);
  const [projectTypes, setProjectTypes] = React.useState([] as ListItem[]);
  const [serviceLevels, setServiceLevels] = React.useState([] as ListItem[]);
  const [divisions, setDivisions] = React.useState([] as ListItem[]);
  const [clientPMs, setClientPMs] = React.useState([] as ListItem[]);
  const [internalPMs, setInternalPMs] = React.useState([] as ListItem[]);
  const [accountingUsers, setAccountingUsers] = React.useState(
    [] as ListItem[]
  );
  const [selectedTeam, setSelectedTeam] = React.useState([] as ListItem[]);

  const [project, setProject] = React.useState<ProjectVm>({
    projectNumber: '',
    clientId: 0,
    brandId: 0,
    name: '',
    siteId: '',
    internalPmId: '',
    address: '',
    city: '',
    state: '',
    zip: '',
    serviceLevelId: 0,
    projectTypeId: 0,
    budget: 0,
    siteSuperId: '',
    accountantId: '',
    teamMemberIds: [] as string[],
    divisionId: 0,
    copyTeamId: null,
  } as ProjectVm);

  const projectStore = useSelector((s: ApplicationState) => s.projects);

  const dispatch = useDispatch();
  const CSGComponent = MyAuthElement([
    KnownRoles.Admin,
    KnownRoles.CSGPM,
    KnownRoles.CoreSuperUser,
  ]);

  React.useEffect(() => {
    _getDDOptions(0);
    _getClients();
    _getInternalPMs();
    _getAccountingUsers();
  }, []);

  const _getDDOptions = (clientId: number) => {
    fetch(`api/Client/NewProjectOpts?clientId=${clientId}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        const pmItems = data.clientPMs
          ? data.clientPMs.map((x: ContactCard) => {
              return { id: x.userId, value: x.name } as ListItem;
            })
          : [];
        setBrands(data.brands);
        setProjectTypes(data.types);
        setServiceLevels(data.levels);
        setDivisions(data.divisions);
        setClientPMs(pmItems);
      });
  };

  const _getClients = () => {
    fetch(`api/Client/Get`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setClients(data);
      });
  };

  const _getInternalPMs = () => {
    fetch(`api/User/GetInUserCompany`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setInternalPMs(data);
      });
  };

  const _getAccountingUsers = () => {
    fetch(`api/User/GetAllAccounting`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setAccountingUsers(data);
      });
  };

  const _handleClientChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selected = e.target.value;
    if (selected) {
      setProject({
        ...project,
        brandId: 0,
        projectTypeId: 0,
        serviceLevelId: 0,
        externalPmId: '',
        clientId: !isNaN(parseInt(selected)) ? parseInt(selected) : 0,
      });
      _getDDOptions(!isNaN(parseInt(selected)) ? parseInt(selected) : 0);
    }
  };

  const _handleCopyTeamChange = (id: string | number) => {
    fetch(`api/ProjectTeam/GetTeam?id=${id}&asListItem=${true}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setSelectedTeam(data);
          setProject({
            ...project,
            teamMemberIds: [...data.map((x) => x.id.toString())],
            copyTeamId: +id,
          });
        }
      });
  };

  const save = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.currentTarget.disabled = true;
    e.currentTarget.innerText = 'Saving';

    if (
      project.clientId == 0 ||
      /*brandId == 0 ||*/ project.name == '' ||
      project.siteId == '' ||
      project.internalPmId == '' ||
      project.accountantId == '' ||
      project.divisionId == 0 ||
      /*extPm == '' ||*/ project.address == '' ||
      project.city == '' ||
      project.state == '' ||
      project.zip ==
        '' /*|| budget == 0 || serviceLevelId == 0 || projectTypeId == 0*/
    ) {
      alert(
        "Oops, it appears you haven't filled out all fields. Please enter a value for all fields before submitting"
      );
      e.currentTarget.disabled = false;
      e.currentTarget.innerText = 'Add';
    } else {
      dispatch(ProjectActions.addProject(project));
      //this.props.close()
    }
  };

  const brandOpts = React.useMemo(() => {
    return brands
      ? brands.map((x) => (
          <option key={x.id} value={x.id}>
            {x.value}
          </option>
        ))
      : [];
  }, [brands]);

  const clientOpts = React.useMemo(() => {
    return clients
      ? clients.map((x) => (
          <option key={x.id} value={x.id}>
            {x.value}
          </option>
        ))
      : [];
  }, [clients]);

  const typeOpts = React.useMemo(() => {
    return projectTypes
      ? projectTypes.map((x) => (
          <option key={x.id} value={x.id}>
            {x.value}
          </option>
        ))
      : [];
  }, [projectTypes]);

  const levelOpts = React.useMemo(() => {
    return serviceLevels
      ? serviceLevels.map((x) => (
          <option key={x.id} value={x.id}>
            {x.value}
          </option>
        ))
      : [];
  }, [serviceLevels]);

  const divisionOpts = React.useMemo(() => {
    return divisions
      ? divisions.map((x) => (
          <option key={x.id} value={x.id}>
            {x.value}
          </option>
        ))
      : [];
  }, [divisions]);

  React.useEffect(() => {
    setProject({ ...project, externalPmId: '' });
  }, [clientPMs]);

  return (
    <Modal modalClass="add-project-modal">
      <div className="modal-header">
        <h4 className="modal-title">Add New Project</h4>
      </div>
      <div className="modal-body no-overflow" style={{ width: '100%' }}>
        <div id="newProjectForm">
          <div className="form-group">
            <p>
              <small>* denotes a required field</small>
            </p>
            <div className="row">
              <div className="col-sm-6">
                Project Number:
                <input
                  onChange={(e) =>
                    setProject({ ...project, projectNumber: e.target.value })
                  }
                  value={project.projectNumber}
                  className="form-control"
                  name="projectNumber"
                />
              </div>
              <CSGComponent class="col-sm-6">
                Client*:
                <select
                  className="form-control"
                  name="clientId"
                  value={project.clientId}
                  onChange={_handleClientChange}
                >
                  <option> Select... </option>
                  {clientOpts}
                </select>
              </CSGComponent>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Brand / End User:
                <select
                  value={project.brandId}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      brandId: !isNaN(parseInt(e.target.value))
                        ? parseInt(e.target.value)
                        : 0,
                    })
                  }
                  className="form-control"
                  name="brandId"
                >
                  <option> Select... </option>
                  {brandOpts}
                </select>
              </div>

              <div className="col-sm-6">
                Service Level:
                <select
                  value={project.serviceLevelId}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      serviceLevelId: !isNaN(parseInt(e.target.value))
                        ? parseInt(e.target.value)
                        : 0,
                    })
                  }
                  className="form-control"
                  name="svcLvl"
                >
                  <option> Select... </option>
                  {levelOpts}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Type:
                <select
                  value={project.projectTypeId}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      projectTypeId: !isNaN(parseInt(e.target.value))
                        ? parseInt(e.target.value)
                        : 0,
                    })
                  }
                  className="form-control"
                  name="projectType"
                >
                  <option> Select... </option>
                  {typeOpts}
                </select>
              </div>
              <div className="col-sm-6">
                Project Name/Description*:
                <input
                  value={project.name}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      name: e.target.value,
                    })
                  }
                  className="form-control"
                  name="projectName"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Site Id*:
                <input
                  value={project.siteId}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      siteId: e.target.value,
                    })
                  }
                  className="form-control"
                  name="siteId"
                />
              </div>
              <div className="col-sm-6">
                Internal PM*:
                <FilterableSelect
                  id="internalPM-select"
                  items={internalPMs}
                  onChange={(value) =>
                    setProject({ ...project, internalPmId: value })
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Project Accountant*:
                <FilterableSelect
                  id="accountant-select"
                  items={accountingUsers}
                  onChange={(value) =>
                    setProject({ ...project, accountantId: value })
                  }
                />
              </div>
              <div className="col-sm-6">
                Division*:
                <select
                  value={project.divisionId ?? 0}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      divisionId: !isNaN(parseInt(e.target.value))
                        ? parseInt(e.target.value)
                        : 0,
                    })
                  }
                  className="form-control"
                  name="divisionId"
                >
                  <option> Select... </option>
                  {divisionOpts}
                </select>
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Site Super:
                <FilterableSelect
                  id="site-super-select"
                  items={internalPMs}
                  onChange={(value) =>
                    setProject({ ...project, siteSuperId: value })
                  }
                />
              </div>
              <div className="col-sm-6">
                Client PM:
                <FilterableSelect
                  id="externalPM-select"
                  items={clientPMs}
                  openField={true}
                  defaultVal={project.externalPmId}
                  onChange={(value) =>
                    setProject((prevState) => ({
                      ...prevState,
                      externalPmId: value,
                    }))
                  }
                  onBlur={(value) => {
                    setProject((prevState) => ({
                      ...prevState,
                      externalPmId: value,
                    }));
                  }}
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                Budget:
                <input
                  value={project.budget}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      budget: !isNaN(parseInt(e.target.value))
                        ? parseInt(e.target.value)
                        : 0,
                    })
                  }
                  type="number"
                  className="form-control"
                  name="budget"
                />
              </div>
              <div className="col-sm-6">
                Address*:
                <input
                  value={project.address}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      address: e.target.value,
                    })
                  }
                  className="form-control"
                  name="address"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-6">
                City*:
                <input
                  value={project.city}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      city: e.target.value,
                    })
                  }
                  className="form-control"
                  name="city"
                />
              </div>
              <div className="col-sm-2">
                State*:
                <input
                  value={project.state}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      state: e.target.value,
                    })
                  }
                  className="form-control"
                  name="state"
                />
              </div>
              <div className="col-sm-3">
                Zip*:
                <input
                  value={project.zip}
                  onChange={(e) =>
                    setProject({
                      ...project,
                      zip: e.target.value,
                    })
                  }
                  className="form-control"
                  name="zip"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-sm-12">
                Project Team Members:
                <div style={{ padding: '3px' }}>
                  <JoinedList
                    availableItems={internalPMs}
                    selectedItems={selectedTeam}
                    title=""
                    onChange={(items) => {
                      setSelectedTeam(items);
                      setProject({
                        ...project,
                        teamMemberIds: items.map((x) => x.id.toString()),
                      });
                    }}
                  />
                </div>
              </div>
              <div className="col-sm-6" style={{ marginTop: '5px' }}>
                Copy Team From Project:
                <FilterableSelect
                  id="copy-project-team"
                  defaultVal={project.copyTeamId ?? undefined}
                  items={projectStore.projects.map(
                    (p) => new ListItem(p.id, `${p.name} (${p.clientName})`)
                  )}
                  onChange={_handleCopyTeamChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-blue"
          onClick={save}
          disabled={projectStore.isLoading}
        >
          Add
        </button>
        <span
          className="btn btn-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </span>
      </div>
    </Modal>
  );
};
