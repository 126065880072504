import * as React from "react";
import { useSelector, useDispatch } from "react-redux";

import Modal from "../components/Modal";
import {
  ChecklistStatus,
  ChecklistDetail,
  ProjectTeamMember,
  ChecklistType,
  ListItem,
} from "../interfaces/interfaces";
import { ApplicationState } from "../store/index";
import {
  ChecklistGrid,
  getTypeController, ChecklistGridApi
} from "./ChecklistGrid";
import { actionCreators as globalActions } from "../store/global";
import { actionCreators as projectActions } from "../store/projectDetail";
import { formatDate } from "../helpers/formatters";
import { ProjectRole } from "../config/global/ProjectRoles";
import { GlobalMessageBox } from "../components/MessageBox";
import { ChecklistSection } from "./ChecklistSection";
import {
  updateAllSectionAssignee,
  updateChecklistHeaderSectionAssignee,
} from "src/services/checklists";
import FilterableSelect from "src/components/FilterableSelect";
import styled from "styled-components";
import { Loader } from "src/components/Loader";

const AssigneeDiv = styled.div({
  display: "flex",
  width: "100%",
  maxWidth: "calc(100% - 150px)",
  alignItems: "baseline",
});

const CollapseAllDiv = styled.div({
  display: "flex",
  justifyContent: "space-between",
});

export const ChecklistDetailModal = (props: {
  id: number;
  type: ChecklistType;
  checklistsGridContext: React.Context<ChecklistGridApi>
}) => {
  const { id, type, checklistsGridContext } = props;
  const api = React.useContext(checklistsGridContext);
  const dispatch = useDispatch();
  const [checklist, setChecklist] = React.useState({} as ChecklistDetail);
  const [selectedListRoleInfo, setSelectedListRoleInfo] = React.useState({
    restricted: false,
    roles: [] as ProjectRole[],
  });
  const [editTitle, setEditTitle] = React.useState(false);
  const [isPm, setIsPm] = React.useState(false);
  const [isCreator, setIsCreator] = React.useState(false);
  const [showAssignAll, setShowAssignAll] = React.useState(false);
  const [showAssignAllButton, setShowAssignAllButton] = React.useState(false);
  const [assignAllUser, setAssignAllUser] = React.useState("");
  const [listUsers, setListUsers] = React.useState<ProjectTeamMember[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [isAllCollapsed, setIsAllCollapsed] = React.useState(false);

  const userStore = useSelector((s: ApplicationState) => s.user);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const globalStore = useSelector((s: ApplicationState) => s.global);
  const controller = getTypeController(type);

  const _getChecklist = () => {
    setLoading(true);
    fetch(`api/${controller}/Detail?headerId=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        setLoading(false);
        if (resOk) setChecklist(data);
        else alert(data.message);
      });
  };

  const _getListRoles = (templateId: number) => {
    if (templateId) {
      fetch(`api/ChecklistConfig/GetListRoles?listId=${templateId}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) setSelectedListRoleInfo(data);
        });
    }
  };

  const _approve = () => {
    fetch(`api/${controller}/ApproveList?headerId=${id}`, { method: "PUT" })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setChecklist(data);
          api.refreshList();
        } else alert(data.message);
      });
  };

  const _start = () => {
    fetch(`api/${controller}/StartList?headerId=${id}`, { method: "PUT" })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setChecklist(data);
          api.refreshList();
        } else alert(data.message);
      });
  };

  const _complete = () => {
    fetch(`api/${controller}/CompleteList?headerId=${id}`, { method: "PUT" })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setChecklist(data);
          api.refreshList();
        } else alert(data.message);
      });
  };

  const _cancel = () => {
    fetch(`api/${controller}/CancelList?headerId=${id}`, { method: "PUT" })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setChecklist(data);
          api.refreshList();
        } else dispatch(globalActions.updateMessage(data.message));
      });
  };

  const _update = (prop: string, value: string) => {
    fetch(`api/${controller}/UpdateList?headerId=${id}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ fieldName: prop, value: value }),
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setChecklist(data);
          api.refreshList();
        } else alert(data.message);
      });
  };

  const _updateFieldEntry = (entryId: number, value: string) => {
    fetch(`api/${controller}/UpdateField?headerId=${id}&entryId=${entryId}`, {
      method: "PUT",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ fieldName: "", value: value }),
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setChecklist(data);
        else alert(data.message);
      });
  };

  const _updateNotes = (entryId: number, notes: string) => {
    fetch(
      `api/${controller}/UpdateFieldNotes?headerId=${id}&entryId=${entryId}`,
      {
        method: "PUT",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ fieldName: "", value: notes }),
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setChecklist(data);
        else alert(data.message);
      });
  };

  const _uploadFiles = (e: React.ChangeEvent<HTMLInputElement>) => {
    const form = new FormData();
    const files = e.currentTarget.files,
      entryId = e.currentTarget.getAttribute("data-id");
    if (files && files.length) {
      for (let i = 0; i < files.length; ++i)
        form.append("files", files[i], files[i].name);
    }
    fetch(`api/${controller}/UploadFiles?headerId=${id}&entryId=${entryId}`, {
      method: "PUT",
      //headers: { 'Content-Type': 'multipart/form-data' },
      body: form,
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setChecklist(data);
        else alert(data.message);
      });
  };

  const _deleteFieldAttachment = (fileId: number) => {
    fetch(
      `api/${controller}/Attachment?headerId=${checklist.id}&fileId=${fileId}`,
      { method: "Delete" }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) setChecklist(data);
        else alert(data.message);
      });
  };

  const _onSectionAssigneeAdd = async (sectionId: number, userId: string) => {
    const checklistData = await updateChecklistHeaderSectionAssignee(
      checklist.id,
      sectionId,
      userId,
      controller
    );
    if (checklistData) {
      setChecklist(checklistData);
    }
  };

  const onAllSectionAssigneeAdd = async (userId: string) => {
    setLoading(true);
    const checklistData = await updateAllSectionAssignee(
      checklist.id,
      userId,
      controller
    );
    if (checklistData) {
      setChecklist(checklistData);
      checkAllAssign("");
    }
    setLoading(false);
  };

  React.useEffect(() => {
    const isPm = userStore.user
      ? userStore.user.userId === projectStore.detail.internalPmId
      : false;

    const isCreator = userStore.user
      ? userStore.user.userId === checklist.userId
      : false;
    setIsPm(isPm);
    setIsCreator(isCreator);
  }, [userStore, projectStore, checklist.userId]);

  React.useEffect(() => {
    _getChecklist();
  }, []);

  React.useEffect(() => {
    // If it's a project checklist we want to load the project detail store to get the project team members populated
    if (
      type === ChecklistType.Project &&
      !projectStore.detail.id &&
      checklist.project?.id != null
    ) {
      dispatch(projectActions.getDetail(checklist.project.id));
    }
  }, [checklist.parentRecordId]);

  React.useEffect(() => {
    // If it's a project checklist we want to load the project detail store to get the project team members populated
    if (type === ChecklistType.Project && checklist.templateId != null) {
      _getListRoles(checklist.templateId);
    }
  }, [checklist.templateId]);

  React.useEffect(() => {
    const listUsers =
      //projectStore.detail.teamMembers //&& selectedListRoleInfo.restricted
      //	? projectStore.detail.teamMembers.filter(
      //			(x) =>
      //				selectedListRoleInfo.roles.findIndex((y) => y.id === x.roleId) !==
      //				-1
      //	  )
      //	:
      projectStore.detail.teamMembers ||
      globalStore.users.map((x) => {
        return {
          userId: x.userId,
          userFullName: x.fullName,
        } as ProjectTeamMember;
      });
    setListUsers(listUsers);
  }, [projectStore, selectedListRoleInfo]);

  const isLocked = React.useMemo(() => {
    if (checklist) {
      return (
        checklist.status == ChecklistStatus.Approved ||
        !checklist.sections?.some((x) => x.userId == userStore.user?.userId)
      );
    } else {
      return false;
    }
  }, [checklist, userStore]);

  const checkAllAssign = (item: string) => {
    if (item !== "" && listUsers.some((x) => x.userId === item)) {
      setShowAssignAllButton(true);
      setAssignAllUser(item);
    } else {
      setShowAssignAllButton(false);
      setAssignAllUser("");
      setShowAssignAll(false);
    }
  };

  return (
    <>
      <Loader loading={loading} />
      {checklist.id &&
        <Modal modalClass="checklist-detail">
          <GlobalMessageBox />
          <div className="modal-header">
            {editTitle ? (
              <div style={{ display: "flex" }}>
                <input
                  className="form-control"
                  defaultValue={checklist.title}
                  onBlur={(e) => _update("title", e.currentTarget.value)}
                />
                <button
                  className="btn btn-sm fas fa-check"
                  onClick={() => setEditTitle(false)}
                ></button>
              </div>
            ) : (
              <h5>
                {checklist.title}
                {checklist.renamable && !isLocked && (
                  <span
                    className="fas fa-edit"
                    onClick={() => setEditTitle(true)}
                  ></span>
                )}
              </h5>
            )}
          </div>
          <div className="modal-body custom-scrollbar">
            <div className="checklist-field">
              <div className="form-group">
                <div className="inline-label">Due Date</div>
                <div>
                  {checklist.dueDate ? formatDate(checklist.dueDate) : "N/A"}
                </div>
              </div>
            </div>
            {!isLocked && (isPm || isCreator) && (
              <>
                {!showAssignAll ? (
                  <div className="form-group">
                    <button
                      className="btn btn-sm btn-white"
                      onClick={() => setShowAssignAll(true)}
                    >
                      Assign all sections
                    </button>
                  </div>
                ) : (
                  <>
                    <AssigneeDiv className="form-group">
                      <FilterableSelect
                        id={"assign-all-select"}
                        items={listUsers.map(
                          (x) => new ListItem(x.userId, x.userFullName)
                        )}
                        onChange={(x) => checkAllAssign(x)}
                      />
                      <button
                        style={{ marginLeft: "8px", marginRight: "8px" }}
                        className="btn btn-sm btn-blue"
                        onClick={() => onAllSectionAssigneeAdd(assignAllUser)}
                        disabled={!showAssignAllButton}
                      >
                        Save
                      </button>
                      <button
                        className="btn btn-sm btn-outline-secondary"
                        onClick={() => checkAllAssign("")}
                      >
                        Cancel
                      </button>
                    </AssigneeDiv>
                    <div className="form-group">
                      <small style={{ color: "red" }}>
                        This will assign all sections to the selected user. Any
                        prior assignments will be changed to the selected user.
                      </small>
                    </div>
                    <div className="form-group"></div>
                  </>
                )}
              </>
            )}
            <CollapseAllDiv className={"form-group"}>
              <p>
                <small>* = required field</small>
              </p>
              <button
                className="btn btn-sm btn-white"
                onClick={() => setIsAllCollapsed(!isAllCollapsed)}
              >
                {isAllCollapsed ? "Expand" : "Collapse"} All Sections
              </button>
            </CollapseAllDiv>
            {checklist.sections.map((x) => (
              <ChecklistSection
                isAllCollapsed={isAllCollapsed}
                key={x.id}
                user={userStore.user}
                onSectionAssigneeAdd={_onSectionAssigneeAdd}
                updateFieldEntry={_updateFieldEntry}
                updateNotes={_updateNotes}
                deleteFieldAttachment={_deleteFieldAttachment}
                uploadFiles={_uploadFiles}
                section={x}
                projectTeamMembers={listUsers}
                checklist={checklist}
                isProjectManager={isPm}
              />
            ))}
            <div style={{ marginBottom: "0px" }} className="form-group">
              <h5>Final Comments</h5>
            </div>
            <div className="form-group">
              <textarea
                disabled={isLocked}
                style={{ width: "100%" }}
                className="form-control"
                defaultValue={checklist.comments}
                onBlur={(e) => _update("comments", e.currentTarget.value)}
              ></textarea>
            </div>
          </div>

          <div className="modal-footer">
            {isPm &&
              checklist.status !== ChecklistStatus.Approved &&
              checklist.status !== ChecklistStatus.Canceled &&
              checklist.type !== ChecklistType.Subcontractor ? ( // Add this condition
              <div className="btn btn-sm btn-red" onClick={_cancel}>
                Cancel
              </div>
            ) : (
              []
            )}
            {(isPm || isCreator) &&
              checklist.status === ChecklistStatus.Started && (
                <div className="btn btn-sm btn-green" onClick={_complete}>
                  Complete
                </div>
              )}
            {(isPm || isCreator) &&
              checklist.status === ChecklistStatus.Completed ? (
              <div className="btn btn-sm btn-green" onClick={_approve}>
                Approve
              </div>
            ) : (
              []
            )}
            <div
              className="btn btn-sm btn-outline-secondary"
              onClick={() => api.toggleDetailId(null)}
            >
              Close
            </div>
          </div>
        </Modal>
      }
    </>
  );
};
