import * as React from 'react';
import { FinancialsForm, UnvettedPartner } from '../interfaces/interfaces';
import Modal, { Modal2 } from '../components/Modal';

import { F40FormGroup, F40FormRow, F40FormSection } from './F40Detail';
import { formatDateTime } from '../helpers/formatters';
import { KnownRoles, hasRole } from '../auth/auth';
import { useDispatch, useSelector } from 'react-redux';
import { ApplicationState } from '../store/index';
import {
  RejectPartnerPhaseTwoFormModal,
  ReviewPartnerPhaseTwoFormModal,
} from './ReviewPartnerPhaseTwoFormModal';
import { convertFilePathToPartnerRegistrationSite } from './UnvettedPartnerDetail';

export const FinancialsFormComponent = (props: {
  partner: UnvettedPartner;
  refresh: () => void;
}) => {
  const { partner } = props,
    { financialsForm: form } = partner;
  const userStore = useSelector((s: ApplicationState) => s.user);

  const [showReviewModal, setShowReviewModal] = React.useState(false);
  const [showRejectionModal, setShowRejectionModal] = React.useState(false);
  const [popupMessage, setPopupMessage] = React.useState(null as string | null);

  const popupMessageComponent = popupMessage ? (
    <Modal2>
      <div className="modal-body">{popupMessage}</div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-outline-secondary"
          style={{ color: 'black' }}
          onClick={() => setPopupMessage(null)}
        >
          Close
        </button>
      </div>
    </Modal2>
  ) : (
    []
  );

  const formComponent = form ? (
    <div className="f40-form">
      <h2 style={{ textAlign: 'center' }}>Qualified Financial Information</h2>
      {form.isSubmitted && (
        <div>
          <F40FormGroup width="100%" noFlex>
            <div>Submitted on {formatDateTime(form.submittedDate)}</div>
          </F40FormGroup>
        </div>
      )}
      {form.isReviewed && (
        <div>
          <F40FormGroup width="100%" noFlex>
            <div>
              Reviewed by <b>{form.reviewerName}</b> on{' '}
              {formatDateTime(form.reviewedDate)}
            </div>
          </F40FormGroup>
        </div>
      )}
      <F40FormSection title="Section 1 - General">
        <F40FormRow>
          <F40FormGroup label="Company Name" width="100%">
            <input disabled value={form.companyName} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Address" width="33%">
            <input disabled value={form.address} />
          </F40FormGroup>
          <F40FormGroup label="City" width="33%">
            <input disabled value={`${form.city}`} />
          </F40FormGroup>
          <F40FormGroup label="State/Province" width="33%">
            <input disabled value={`${form.stateProvince}`} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Zip/Postal Code" width="33%">
            <input disabled value={form.zip} />
          </F40FormGroup>
          <F40FormGroup label="Country" width="33%">
            <input disabled value={form.country} />
          </F40FormGroup>
          <F40FormGroup label="Phone" width="33%">
            <input disabled value={form.phone} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="D&B Number" width="33%">
            <input disabled name="dBnumber" value={form.dbNumber} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              List your three largest contract values for the last twelve
              months:
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Contract 1" width="33%">
            <input
              disabled
              name="contractAmount1"
              defaultValue={form.contractAmount1}
            />
          </F40FormGroup>
          <F40FormGroup label="Contract 2" width="33%">
            <input
              disabled
              name="contractAmount2"
              defaultValue={form.contractAmount2}
            />
          </F40FormGroup>
          <F40FormGroup label="Contract 3" width="33%">
            <input
              disabled
              name="contractAmount3"
              defaultValue={form.contractAmount3}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="25%"
            label="Is your company subject to any liens?"
            labelWidth="300px"
          >
            <input
              disabled
              type="checkbox"
              name="subjectToLiens"
              checked={form.subjectToLiens}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          {form.subjectToLiens && (
            <F40FormGroup width="100%" label="Please explain the liens" noFlex>
              <textarea
                disabled
                rows={3}
                name="liensDescription"
                value={form.liensDescription}
              />
            </F40FormGroup>
          )}
        </F40FormRow>
        <F40FormRow>
          <p>
            <b>
              The following figures refer to your financials at the end of your
              most recent fiscal year. Please indicate the month and year in
              which your fiscal year ended:
            </b>
          </p>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Month" width="20%" labelWidth="180px">
            <select
              disabled
              name="recentFYMonth"
              value={form.recentFYMonth || ''}
              style={{ width: 'calc(100% - 180px)' }}
            >
              <option value=""></option>
              <option value="Jan">Jan</option>
              <option value="Feb">Feb</option>
              <option value="Mar">Mar</option>
              <option value="Apr">Apr</option>
              <option value="May">May</option>
              <option value="Jun">Jun</option>
              <option value="Jul">Jul</option>
              <option value="Aug">Aug</option>
              <option value="Sep">Sep</option>
              <option value="Oct">Oct</option>
              <option value="Nov">Nov</option>
              <option value="Dec">Dec</option>
            </select>
          </F40FormGroup>
          <F40FormGroup label="Year" width="21.2%" labelWidth="50px">
            <input disabled name="recentFYYear" value={form.recentFYYear} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Revenue" width={'33%'}>
            <input disabled name="fyRevenues" defaultValue={form.fyRevenues} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Current Assets" width={'33%'}>
            <input
              disabled
              name="fyCurrentAssets"
              defaultValue={form.fyCurrentAssets}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Total Assets" width={'33%'}>
            <input
              disabled
              name="fyTotalAssets"
              defaultValue={form.fyTotalAssets}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Current Liabilities" width={'33%'}>
            <input
              disabled
              name="fyCurrentLiabilities"
              defaultValue={form.fyCurrentLiabilities}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Total Liabilities" width={'33%'}>
            <input
              disabled
              name="fyTotalLiabilities"
              defaultValue={form.fyTotalLiabilities}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Long Term Debt" width={'33%'}>
            <input
              disabled
              name="fyLongTermDebt"
              defaultValue={form.fyLongTermDebt}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Retained Earnings" width={'33%'}>
            <input
              disabled
              name="fyRetainedEarnings"
              defaultValue={form.fyRetainedEarnings}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Shareholder Equity" width={'33%'}>
            <input disabled name="fyEquity" defaultValue={form.fyEquity} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Net Income" width={'33%'}>
            <input
              disabled
              name="fyNetIncome"
              defaultValue={form.fyNetIncome}
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup label="Cash Flow From Operations" width={'33%'}>
            <input disabled name="fyCashFlow" defaultValue={form.fyCashFlow} />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="560px"
            label="Has your firm or its officers declared a business bankruptcy in the last five years?"
          >
            <select
              disabled
              name="bankruptcyLast5Years"
              value={form.bankruptcyLast5Years ? 'Yes' : 'No'}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </F40FormGroup>
        </F40FormRow>
        {form.bankruptcyLast5Years && (
          <F40FormRow>
            <F40FormGroup
              width="100%"
              labelWidth="310px"
              label="Please explain the bankrupcty declaration"
              noFlex
            >
              <textarea
                disabled
                rows={3}
                value={form.bankruptcyDescription}
                name="bankruptcyDescription"
              ></textarea>
            </F40FormGroup>
          </F40FormRow>
        )}
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="315px"
            label="Are you currently involved in any litigation?"
          >
            <select
              disabled
              name="involvedInLitigation"
              value={form.involvedInLitigation ? 'Yes' : 'No'}
            >
              <option value="Yes">Yes</option>
              <option value="No">No</option>
            </select>
          </F40FormGroup>
        </F40FormRow>
        {form.involvedInLitigation && (
          <F40FormRow>
            <F40FormGroup
              width="100%"
              labelWidth="250px"
              label="Please explain the litigation"
              noFlex
            >
              <textarea
                disabled
                rows={3}
                value={form.litigationExplanation}
                name="litigationExplanation"
              ></textarea>
            </F40FormGroup>
          </F40FormRow>
        )}
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="What is your single-job bonding limit"
          >
            <input
              disabled
              value={form.singleJobBondingLimit}
              name="singleJobBondingLimit"
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="What is your aggregate bonding limit"
          >
            <input
              disabled
              value={form.aggregateBondingLimit}
              name="aggregateBondingLimit"
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup
            width="100%"
            labelWidth="350px"
            label="Have you ever had a claim against your bond"
          >
            <input
              disabled
              value={form.claimAgainstBond}
              name="claimAgainstBond"
            />
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup noFlex label="Attachments" width="100%">
            {form.attachments.map((x) => {
              const filePath = convertFilePathToPartnerRegistrationSite(
                x.filePath
              );
              return (
                <div key={x.id}>
                  <a href={filePath} target="_blank">
                    {x.fileName}
                  </a>
                </div>
              );
            })}
          </F40FormGroup>
        </F40FormRow>
        <F40FormRow>
          <F40FormGroup width="50%" label="Signature">
            <input
              disabled
              name="digitalSignature"
              value={form.digitalSignature}
            />
          </F40FormGroup>
          <F40FormGroup width="50%" label="Title">
            <input
              disabled
              name="digitalSignatureTitle"
              value={form.digitalSignatureTitle}
            />
          </F40FormGroup>
        </F40FormRow>
      </F40FormSection>
      {hasRole(userStore.user, [KnownRoles.SubsAdmin]) &&
      form.isSubmitted &&
      !form.isReviewed ? (
        <F40FormRow>
          <button
            className="btn btn-sm btn-green"
            onClick={() => setShowReviewModal(true)}
          >
            Mark as Reviewed
          </button>
        </F40FormRow>
      ) : (
        []
      )}
      {hasRole(userStore.user, [KnownRoles.SubsAdmin]) && form.isSubmitted ? (
        <F40FormRow>
          <button
            className="btn btn-sm btn-red"
            onClick={() => setShowRejectionModal(true)}
          >
            Reject
          </button>
        </F40FormRow>
      ) : (
        []
      )}
    </div>
  ) : (
    <div
      style={
        {
          /*backgroundColor: 'rgb(230, 230, 230)'*/
        }
      }
    >
      <h4>{partner.vendorName} has not started this form yet</h4>
    </div>
  );

  return (
    <div className="f40-page">
      {showReviewModal && (
        <ReviewPartnerPhaseTwoFormModal
          partnerId={partner.id}
          formType="Financial"
          close={(callRefresh: boolean) => {
            if (callRefresh) {
              props.refresh();
            }
            setShowReviewModal(false);
          }}
        />
      )}
      {showRejectionModal && (
        <RejectPartnerPhaseTwoFormModal
          partnerId={partner.id}
          formType="Financial"
          close={(callRefresh: boolean) => {
            if (callRefresh) {
              props.refresh();
            }
            setShowRejectionModal(false);
          }}
        />
      )}
      {popupMessageComponent}
      {formComponent}
    </div>
  );
};
