import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MessageBox } from "src/components/MessageBox";
import { ConfirmPORequestModal, POAddModal } from "src/purchaseOrder/POAddModal";
import { addProcurementPO, requestProcurementPO } from "src/services/purchaseOrders/index";
import { TabContent, TabLink, Tabs } from "src/tabs/index";
import DocIcon from "../components/DocIcon";
import { EventHistoryLog } from "../components/EventHistoryLog";
import Modal, { Modal2 } from "../components/Modal";
import {
  ProcurementItemStatus,
  ProcurementQuoteStatus,
} from "../interfaces/enums";
import {
  IDocumentInfo,
  ListItem,
  ProcurementItem,
  ProcurementItemReviewer,
  ProcurementQuote,
  ProjectDetailVm,
  PurchaseOrder,
  RFQ,
  VendorVm,
} from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { ApplicationState } from "../store/index";
import { actionCreators as procurementActions } from "../store/procurement";
import { AddQuoteComponent } from "./AddQuoteComponent";
import { ProcurementComments } from "./ProcurementComments";
import {
  FieldFilterableSelect,
  FieldInput,
  FieldTextArea,
} from "../components/FieldComponents/index";
import { ProcurementQuoteDetailModal } from "./ProcurementQuoteDetail";
import { QuoteSummaryLine } from "./QuoteSummaryLine";
import { PurchaseOrderComponent } from "src/purchaseOrder/PurchaseOrder";
import styled from "styled-components";
import { FilterableSelectContainer } from "src/components/FilterableSelectContainer";
import $ from "jquery";
import FilterableSelect from "src/components/FilterableSelect";
import { RFQAddModal } from "../rfqs/RFQAddModal";
import { RFQDetailModal } from "../rfqs/RFQDetailModal"; // Import the RFQDetailModal component
import { Loader } from "../components/Loader";





export const ProcurementItemDetail = (props: {
  item: ProcurementItem;
  project: ProjectDetailVm;
  onPOAdd?: () => void;
  hideEventLog?: boolean;
  modalScroll: boolean;
}) => {
  const [saving, setSaving] = React.useState(false);
  const [showAddReviewer, setShowAddReviewer] = React.useState(false);
  const [showComments, setShowComments] = React.useState(false);
  const [showPOModal, setShowPOModal] = React.useState(false);
  const [showRFQModal, setShowRFQModal] = React.useState(false);
  const [showCancelModal, setShowCancelModal] = React.useState(false);
  const [message, setMessage] = React.useState("");
  const [bodyScroll, setBodyScroll] = React.useState(false);
  const [poWidth, setPOWidth] = React.useState("100px");
  const [reviewerWidth, setReviewerWidth] = React.useState("100px");
  const procurementManagerRef = React.useRef<HTMLDivElement>(null);
  const poManagerRef = React.useRef<HTMLDivElement>(null);
  const reviewerRef = React.useRef<HTMLDivElement>(null);
  const approvedQuoteIdRef = React.useRef<HTMLDivElement>(null);
  const [selectedRFQ, setSelectedRFQ] = React.useState<ProcurementQuote | null>(null);
  const [showAddRFQModal, setShowAddRFQModal] = React.useState(false); // New state for RFQAddModal
  const [notes, setNotes] = React.useState(""); // New state for notes
  const [requestDate, setRequestDate] = React.useState<Date | null>(null); // New state for request date
  const userStore = useSelector((s: ApplicationState) => s.user);
  const globalStore = useSelector((s: ApplicationState) => s.global);
  const dispatch = useDispatch();

  const { item, project } = props;
  const { user } = userStore;

  const userItems = globalStore.users.map(
    (x) => new ListItem(x.userId, x.fullName)
  );
  const userId = (userStore.user || { userId: "" }).userId;
  const reviewer = item.reviewers
    ? item.reviewers.find((x) => x.userId === userId)
    : undefined;
  const isReviewer =
    item.reviewers &&
    reviewer !== undefined &&
    reviewer.approvedQuoteId === null;
  const isApproved = item.status == ProcurementItemStatus.Approved;
  const hasApprovedQuote =
    item.quoteSummaries && item.quoteSummaries.some((x) => x.approved === true);

  const canEdit =
    item.status !== ProcurementItemStatus.Approved &&
    item.status !== ProcurementItemStatus.Canceled &&
    (userId === item.procurementManagerId ||
      userId === item.projectManagerId ||
      userId === item.userId);

  const canViewPObutton =
    userId === item.procurementManagerId || userId === project.internalPmId;

  const canLock =
    item.status !== ProcurementItemStatus.Approved &&
    item.status !== ProcurementItemStatus.Canceled &&
    (userId === item.procurementManagerId || userId === project.internalPmId);

  const _approveQuote = () => {
    const selected = (
      document.querySelector(
        `#approvedQuoteId_select_${item.id} input[name="approvedQuoteId_select_${item.id}"]`
      ) as HTMLInputElement
    ).value;
    if (!selected) alert("You must select a quote to approve");

    dispatch(procurementActions.approveQuote(parseInt(selected), item.id));
  };

  const _removeDocument = (id: number) => {
    dispatch(procurementActions.removeItemAttachment(item.id, id));
  };

  const _update = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      procurementActions.updateItem(
        item.id,
        e.currentTarget.name,
        e.currentTarget.value
      )
    );
  };

  const _uploadDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files.length) {
      dispatch(procurementActions.uploadItemAttachments(item.id, files));
      e.currentTarget.value = "";
    }
  };

  const _handlePORequest = async (cost: number, notes: string) => {
    if (hasApprovedQuote) {
      setSaving(true);
      setShowPOModal(false);
      setRequestDate(new Date());
      const approvedQuoteId = item.quoteSummaries.find((x) => x.approved)?.id;
      if (approvedQuoteId) {
        const result = await requestProcurementPO(approvedQuoteId, item.id, notes, cost);
        if (result === true) {
          dispatch(procurementActions.getItem(item.id));
          //setShowPOModal(false);
          setMessage("Purchase Order successfully requested.");
        } else {
          setMessage(
            result.message
          );
        }
        setSaving(false);
      }
    }
  };

  const handleTop = React.useCallback(
    (ref: HTMLDivElement | null) => {
      if ($(".modal-body")[0]) {
        $(".modal-body")[0].click();
      }
      ref?.querySelector("input")?.blur();
      if (ref) {
        return ref.getBoundingClientRect().top + ref.offsetHeight + "px";
      }
      return "";
    },

    [
      bodyScroll,
      showAddReviewer,
      props.modalScroll,
      procurementManagerRef.current,
      poManagerRef.current,
      reviewerRef.current,
      approvedQuoteIdRef.current,
    ]
  );

  const handleWidth = React.useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        return (ref.children[1] as HTMLDivElement)?.offsetWidth + "px";
      }
      return "";
    },
    [
      bodyScroll,
      props.modalScroll,
      procurementManagerRef.current,
      approvedQuoteIdRef.current,
    ]
  );

  const handleReviewerWidth = React.useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        return (ref.firstChild as HTMLDivElement)?.clientWidth - 200 + "px";
      }
      return "";
    },
    [bodyScroll, props.modalScroll]
  );

  React.useEffect(() => {
    setReviewerWidth(handleReviewerWidth(reviewerRef.current));
  }, [showAddReviewer]);

  const cancelModal = showCancelModal ? (
    <Modal2>
      <div className='modal-header'>
        <h4>Cancel Procurement Item</h4>
      </div>
      <div className='modal-body'>
        <p style={{ fontSize: '1.25em', marginBottom: '0px' }}>Are you sure you want to cancel this procurement item? This cannot be undone.</p>
      </div>
      <div className='modal-footer'>
        <button className='btn btn-x-sm btn-red' onClick={() => {
          dispatch(procurementActions.updateItem(item.id, 'status', ProcurementItemStatus.Canceled));
          setShowCancelModal(false);
        }}>Confirm</button>
        <button className='btn btn-x-sm btn-outline-secondary' onClick={() => setShowCancelModal(false)}>Close</button>
      </div>
    </Modal2>
  ) : []

  return (
    <div className="procurement-detail">
      <Loader loading={saving} />
      <MessageBox message={message} clearMessage={() => setMessage("")} />
      <div
        onScroll={() => setBodyScroll(!bodyScroll)}
        className="procurement-form custom-scrollbar"
      >
        <div className="form-group">
          <label>Item Description</label>
          <FieldInput
            id={item.id}
            field="itemDescription"
            value={item.itemDescription}
            canEdit={canEdit}
            update={_update}
          />
        </div>
        <div className="form-group">
          <label>Status</label>
          <div>{ProcurementItemStatusToText(item.status)}</div>
        </div>
        <FilterableSelectContainer
          top={handleTop(procurementManagerRef.current)}
          ulWidth={handleWidth(procurementManagerRef.current)}
          className="form-group"
          ref={procurementManagerRef}
        >
          <label>Procurement Manager</label>
          <FieldFilterableSelect
            id={item.id}
            field="procurementManagerId"
            value={item.procurementManagerId}
            canEdit={canEdit}
            items={userItems}
            update={(f, v, i) =>
              dispatch(procurementActions.updateItem(i, f, v))
            }
          />
        </FilterableSelectContainer>
        <FilterableSelectContainer
          top={handleTop(poManagerRef.current)}
          ulWidth={poWidth}
          ref={poManagerRef}
          className="form-group"
        >
          <label>PO Manager</label>
          <FieldFilterableSelect
            id={item.id}
            field="assigneeId"
            value={item.assigneeId}
            canEdit={canEdit}
            items={userItems}
            update={(f, v, i) =>
              dispatch(procurementActions.updateItem(i, f, v))
            }
          />
        </FilterableSelectContainer>
        <div className="form-group">
          <label>RFQ Due Date</label>
          <FieldInput
            id={item.id}
            field="rfqDueDate"
            value={item.rfqDueDate}
            canEdit={canEdit}
            update={_update}
            dataType="date"
          />
        </div>
        <div className="form-group">
          <label>Review Due Date</label>
          <FieldInput
            id={item.id}
            field="reviewDueDate"
            value={item.reviewDueDate}
            canEdit={canEdit}
            update={_update}
            dataType="date"
          />
        </div>
        <div className="form-group">
          <label>On-site Due Date</label>
          <FieldInput
            id={item.id}
            field="onSiteDueDate"
            value={item.onSiteDueDate}
            canEdit={canEdit}
            update={_update}
            dataType="date"
          />
        </div>
        <div className="form-group">
          <label>Budget</label>
          <FieldInput
            id={item.id}
            field="budget"
            value={item.budget}
            canEdit={canEdit}
            update={_update}
            dataType="money"
          />
        </div>
        <div className="form-group">
          <label>Shipping Location</label>
          <FieldTextArea
            id={item.id}
            field="shippingLocation"
            value={item.shippingLocation}
            canEdit={canEdit}
            update={_update}
          />
        </div>
        <div className="form-group">
          <label>Notes</label>
          <FieldTextArea
            id={item.id}
            field="notes"
            value={item.notes}
            canEdit={canEdit}
            update={_update}
          />
        </div>
        <div className="form-group">
          <label>Documents</label>
          <div style={{ width: "calc(100% - 220px)", maxWidth: '700px' }}>
            {item.attachments ? (
              <div style={{ marginBottom: "3px" }}>
                {item.attachments.map((x) => (
                  <DocIcon
                    key={x.id}
                    doc={
                      {
                        id: x.id,
                        filePath: x.filePath,
                        timestamp: x.timestamp,
                      } as IDocumentInfo
                    }
                    delete={(id) => _removeDocument(id)}
                  />
                ))}
              </div>
            ) : (
              []
            )}
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            {canEdit && (
              <div style={{ display: "block" }}>
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx"
                  onChange={_uploadDocuments}
                />
              </div>
            )}
          </div>
        </div>
        <div className="form-group">
          <label>Reviewers</label>
          <FilterableSelectContainer
            top={handleTop(reviewerRef.current)}
            ulWidth={reviewerWidth}
            className="form-group"
            ref={reviewerRef}
            style={{
              width: "calc(100% - 220px)",
              maxWidth: '700px',
              alignSelf: "center",
              flexWrap: "wrap",
            }}
          >
            {item.reviewers.map((x) => (
              <div key={x.id} className="reviewer-line">
                {x.userFullName}{" "}
                {canEdit && (
                  <span
                    className="fas fa-times"
                    onClick={() =>
                      dispatch(procurementActions.removeReviewer(item.id, x.id))
                    }
                  ></span>
                )}
              </div>
            ))}
            {showAddReviewer && (
              <>
                <FieldFilterableSelect
                  id={item.id}
                  field="assigneeIds"
                  value={""}
                  canEdit={canEdit}
                  items={userItems}
                  update={(f, v, i) => {
                    dispatch(procurementActions.addReviewer(i, v));
                    setShowAddReviewer(false);
                  }}
                />
                &nbsp; &nbsp;
                <button
                  style={{ marginRight: "8px" }}
                  className="btn btn-x-sm btn-outline-secondary"
                  onClick={() => setShowAddReviewer(false)}
                >
                  Cancel
                </button>
              </>
            )}
            {canEdit && !showAddReviewer ? (
              <div>
                <button
                  style={{ marginRight: "8px" }}
                  className="btn btn-x-sm btn-blue"
                  onClick={() => setShowAddReviewer(true)}
                >
                  Add
                </button>
                <button
                  className="btn btn-x-sm btn-outline-secondary"
                  onClick={() => setShowComments(true)}
                >
                  <span className="fas fa-comment"></span>&nbsp; Comments
                </button>
              </div>
            ) : (
              []
            )}
          </FilterableSelectContainer>
        </div>
        {canLock ? (
          <div className="form-group">
            {hasApprovedQuote && (
              <>
                <button
                  className="btn btn-x-sm btn-green"
                  onClick={() =>
                    dispatch(
                      procurementActions.updateItem(
                        item.id,
                        "status",
                        ProcurementItemStatus.Approved
                      )
                    )
                  }
                >
                  Lock Item Request
                </button>{" "}
                &nbsp;
              </>
            )}
            <button
              className="btn btn-x-sm btn-red"
              onClick={() => setShowCancelModal(true)}>Cancel Item Request
            </button>
          </div>
        ) : (
          []
        )}
        {reviewer ? (
          <div className="form-group" key={isReviewer.toString()}>
            <label>Your Approved Quote</label>
            <FilterableSelectContainer
              top={handleTop(approvedQuoteIdRef.current)}
              ulWidth={'300px'}
              ref={approvedQuoteIdRef}
              style={{ width: "calc(100% - 220px)", maxWidth: '700px', display: 'flex' }}
            >
              <FieldFilterableSelect
                id={item.id}
                field="approvedQuoteId"
                canEdit={
                  isReviewer && item.status === ProcurementItemStatus.Review
                }
                value={reviewer.approvedQuoteId}
                items={item.quoteSummaries.map(
                  (x) => new ListItem(x.id, x.title)
                )}
                update={() => { }}
                style={{ width: "calc(100% - 61px)" }}
              />
              &nbsp; &nbsp;
              {isReviewer && item.status === ProcurementItemStatus.Review && (
                <button
                  style={{ height: "29px", }}
                  className="btn btn-x-sm btn-green"
                  onClick={_approveQuote}
                >
                  Select
                </button>
              )}
            </FilterableSelectContainer>
          </div>
        ) : (
          []
        )}
        <hr />
        <div style={{ display: 'flex' }}>
          {
            //!item.poRequestTimestamp &&
            canViewPObutton &&
            isApproved &&
            hasApprovedQuote && (
              <div style={{ marginRight: '10px' }}>
                {item.poRequestTimestamp ? (
                  <p>PO Requested on: {new Date(item.poRequestTimestamp).toLocaleDateString()}</p>
                ) : (
                  <>
                    <button
                      className="btn btn-x-sm btn-blue"
                      onClick={() => setShowPOModal(true)}
                      disabled={!item.assigneeId}
                    >
                      Request Purchase Order
                    </button>
                    {!item.assigneeId && (
                      <p style={{ color: "red" }}>Please assign a PO Manager.</p>
                    )}
                  </>
                )}
              </div>

            )}

          {item.rfqId && item.rfq && item.procurementManagerId === userId && (
            <div><button className="btn btn-x-sm btn-outline-secondary" onClick={() => setShowRFQModal(true)}>
              View RFQ
            </button></div>
          )}
          {item.status < ProcurementItemStatus.Approved && item.rfqId === null && item.procurementManagerId === userId && (
            <div>
              <button className='btn btn-x-sm btn-blue' onClick={() => setShowAddRFQModal(true)}>Add RFQ</button>
              {showAddRFQModal && <RFQAddModal item={item} close={() => setShowAddRFQModal(false)} />}
            </div>
          )}
        </div>
        {showRFQModal && (
          <RFQDetailModal
            rfq={item.rfq}
            close={() => setShowRFQModal(false)}
            canEdit={item.procurementManagerId === userId}
            updateRfq={(r) => {
              dispatch(procurementActions.updateItemRfq(item.id, r));
            }}
          />
        )}
        {showComments && (
          <ProcurementComments
            item={item}
            close={() => setShowComments(false)}
          />
        )}
      </div>
      {
        !props.hideEventLog && (
          <div className="procurement-history-logs">
            <EventHistoryLog objectId={item.id} objectName="ProcurementItem" />
          </div>
        )
      }
      {
        showPOModal && (
          <ConfirmPORequestModal
            procurementItem={item}
            onConfirm={_handlePORequest}
            onClose={() => setShowPOModal(false)}
          />
        )
      }
      {cancelModal}
    </div >
  );
};

export const ProcurementItemDetailModal = (props: {
  project: ProjectDetailVm;
  item?: ProcurementItem;
  itemId?: number;
  close: () => void;
  onPOAdd?: () => void;
}) => {
  //const [item, setItem] = React.useState(props.item);
  const [modalScroll, setModalScroll] = React.useState(false);

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);

  React.useEffect(() => {
    if (!props.item && props.itemId) {
      const storeItem = procurementStore.procurementItems.find(
        (x) => x.id === props.itemId
      );
      if (!storeItem) alert("Unable to find item in data store");
      //else setItem(storeItem);
    }
  }, []);

  React.useEffect(() => {
    //setItem(props.item);
  }, [props.item]);

  return (
    <Modal modalClass="procurement-modal">
      <div className="modal-header">
        <h4>Item Details</h4>
      </div>
      <div
        onScroll={() => setModalScroll(!modalScroll)}
        className="modal-body custom-scrollbar"
      >
        <ProcurementItemHome
          item={props.item}
          itemId={props.itemId}
          onPOAdd={props.onPOAdd}
          project={props.project}
          modalScroll={modalScroll}
        />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-x-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};

export const ProcurementItemHome = (props: {
  item?: ProcurementItem;
  project?: ProjectDetailVm;
  itemId?: number;
  onPOAdd?: () => void;
  modalScroll: boolean;
  rfqid?: number;
}) => {
  const [item, setItem] = React.useState<ProcurementItem>(
    {} as ProcurementItem
  );
  const [project, setProject] = React.useState({} as ProjectDetailVm);
  //const [showQuoteId, setShowQuoteId] = React.useState(null as number | null);

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);
  const dispatch = useDispatch();

  const { selectedQuoteId } = procurementStore;

  const _getItem = (id: number) => {
    fetch(`api/procurement/itemdetail?id=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setItem(data);
        }
      });
  };



  const _getProject = (id: number) => {
    fetch(`api/project/detail?id=${id}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setProject(data.detail);
        }
      });
  };

  React.useEffect(() => {
    if (props.item) setItem(props.item);
    else if (props.itemId) _getItem(props.itemId);
  }, [props.item, props.itemId]);

  React.useEffect(() => {
    if (item.projectId) {
      _getProject(item.projectId);
    }
  }, [item.projectId]);

  const userStore = useSelector((s: ApplicationState) => s.user);
  const userId = (userStore.user || { userId: "" }).userId;

  const hasApprovedQuote = item?.quoteSummaries?.some(
    (x) => x.approved === true
  );

  const canEditPO =
    (userId === item?.assigneeId || userId === project.internalPmId) &&
    !item?.purchaseOrder?.closed;
  const handleOnPOUpdate = () => {
    item && dispatch(procurementActions.getItem(item.id));
  };

  return (
    <div className="procurement-home">
      <Tabs name="admin-tabs" renderActiveTabContentOnly={true}>
        <div className="tab-links">
          <TabLink to="detail" component="span">
            <span>Detail</span>
          </TabLink>
          <TabLink to="quotes" component="span">
            <span>Quotes</span>
          </TabLink>
          {item?.purchaseOrder && (
            <TabLink to="purchaseOrder" component="span">
              <span>Purchase Order</span>
            </TabLink>
          )}
        </div>
        <div className="content">
          <TabContent for="detail">
            {item.id ? (
              <ProcurementItemDetail
                onPOAdd={props.onPOAdd}
                project={project}
                item={item}
                key={item.lastModifiedTimestamp}
                hideEventLog={true}
                modalScroll={props.modalScroll}
              />
            ) : (
              <StandardGridLoader
                rowCount={12}
                rowContentHeight={30}
                rowPadding={5}
              />
            )}
          </TabContent>
          <TabContent for="quotes">
            <div className="form-group">
              {/*<label>Quotes</label>*/}
              <div style={{ width: "calc(100% - 220px)", maxWidth: '700px' }}>
                {item.id && (
                  <>
                    {item.quoteSummaries?.map((x) => (
                      <QuoteSummaryLine
                        key={x.id}
                        quote={x}
                        detailCallback={(id) => dispatch(procurementActions.setSelectedQuote(id))}
                      />
                    ))}
                    {!hasApprovedQuote &&
                      userId == item.procurementManagerId && (
                        <AddQuoteComponent item={item} />
                      )}
                  </>
                )}
              </div>
            </div>
          </TabContent>
          <TabContent for="purchaseOrder">
            {item?.purchaseOrder && (
              <PurchaseOrderComponent
                onUpdate={handleOnPOUpdate}
                canEdit={canEditPO}
                po={item?.purchaseOrder}
              ></PurchaseOrderComponent>
            )}
          </TabContent>
        </div>
      </Tabs>
      {selectedQuoteId && item && (
        <ProcurementQuoteDetailModal
          hasSisterQuoteApproved={hasApprovedQuote}
          //procurementItem={item}
          isReviewer={item.reviewers && item.reviewers.some(x => x.userId === userId)}
          editable={(userId === project.internalPmId || userId === item.procurementManagerId || userId === item.assigneeId)}
          quoteId={selectedQuoteId}
          close={() => dispatch(procurementActions.setSelectedQuote(null))}
        />
      )}
    </div>
  );
};

export const ProcurementItemStatusToText = (s: ProcurementItemStatus) => {
  switch (s) {
    case ProcurementItemStatus.Draft:
      return "Draft";
    case ProcurementItemStatus.Rfq:
      return "RFQ";
    case ProcurementItemStatus.Review:
      return "Review";
    case ProcurementItemStatus.Approved:
      return "Approved";
    case ProcurementItemStatus.Canceled:
      return "Canceled";
  }
};
