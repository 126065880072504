import * as React from "react";
import { useDispatch, useSelector } from "react-redux";
import DocIcon from "../components/DocIcon";
import { EventHistoryLog } from "../components/EventHistoryLog";
import Modal, { Modal2 } from "../components/Modal";
import { ProcurementQuoteStatus } from "../interfaces/enums";
import {
  IDocumentInfo,
  ListItem,
  ProcurementQuote,
} from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { ApplicationState } from "../store";
import { actionCreators as procurementActions } from "../store/procurement";
import {
  FieldFilterableSelect,
  FieldInput,
  FieldTextArea,
} from "../components/FieldComponents";
import { requestProcurementPO } from "../services/purchaseOrders/index";
import { request } from "https";

export const ProcurementQuoteDetail = (props: {
  //quote?: ProcurementQuote;
  quoteId: number;
  isReviewer: boolean;
  editable: boolean;
  //project: ProjectDetailVm;
  hasSisterQuoteApproved?: boolean;
  //procurementItem: ProcurementItem;
}) => {
  const [quote, setQuote] = React.useState(null as ProcurementQuote | null);
  const [vendors, setVendors] = React.useState([] as ListItem[]);
  const [loading, setLoading] = React.useState(true);
  const [showApproveModal, setShowApproveModal] = React.useState(false);
  const [showRejectModal, setShowRejectModal] = React.useState(false);
  const [showDeleteModal, setShowDeleteModal] = React.useState(false);
  const [requestNotes, setRequestNotes] = React.useState("");
  const [isRequesting, setIsRequesting] = React.useState(false);
  

  const procurementStore = useSelector((s: ApplicationState) => s.procurement);
  const dispatch = useDispatch();

  React.useEffect(() => {
    fetch(`api/vendor/getall?activeOnly=${true}`)
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setVendors(data);
          setLoading(false);
        }
      });
    if (props.quoteId) {
      const storeQuote = procurementStore.quotes.find(
        (x) => x.id === props.quoteId
      );
      if (!storeQuote) alert("Unable to find quote in data store");
      setQuote(storeQuote || null);
    }
  }, [props.quoteId, procurementStore.quotes]);

  if (!quote)
    return (
      <StandardGridLoader rowCount={5} rowContentHeight={30} rowPadding={5} />
    );

  //const userId = (userStore.user || { userId: "" }).userId;
  // eslint-disable-next-line prefer-const
  let { isReviewer, editable } = props;
  const approvable =
    !props.hasSisterQuoteApproved &&
    quote.status === ProcurementQuoteStatus.Pending &&
    editable;

  const _approveQuote = () => {
    dispatch(procurementActions.approveQuote(quote.id, null));
    setTimeout(() => {
      setShowApproveModal(false);
    }, 200);
  };

  const _rejectQuote = () => {
    dispatch(
      procurementActions.updateQuote(
        quote.id,
        "status",
        ProcurementQuoteStatus.Rejected
      )
    );
    setTimeout(() => {
      setShowRejectModal(false);
    }, 200);
  };

  const _removeDocument = (id: number) => {
    dispatch(procurementActions.removeQuoteAttachment(quote.id, id));
  };

  const _update = (
    e:
      | React.FocusEvent<HTMLInputElement>
      | React.FocusEvent<HTMLTextAreaElement>
      | React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch(
      procurementActions.updateQuote(
        quote.id,
        e.currentTarget.name,
        e.currentTarget.value
      )
    );
  };

  const _uploadDocuments = (e: React.ChangeEvent<HTMLInputElement>) => {
    const files = e.currentTarget.files;
    if (files && files.length) {
      dispatch(procurementActions.uploadQuoteAttachments(quote.id, files));
      e.currentTarget.value = "";
    }
  };

  const requestPO = (quoteId: number, itemId: number, notes: string, cost: number) => async (dispatch: any) => {
    try {
      const response = await requestProcurementPO(quoteId, itemId, notes, cost);
      if (response === true) {
        dispatch({ type: 'REQUEST_PO_SUCCESS', payload: { quoteId, itemId, notes, cost } });
      } else {
        /*dispatch({ type: 'REQUEST_PO_FAILURE', error: response.message });*/
      }
    } catch (error) {
      /*dispatch({ type: 'REQUEST_PO_FAILURE', error: error.message });*/
    }
  };

  if (loading)
    return (
      <StandardGridLoader rowCount={5} rowContentHeight={30} rowPadding={10} />
    );

  const approveModal = showApproveModal ? (
    <Modal modalClass="quote-approval-modal double-top-margin">
      <div className="modal-header">
        <h4>Approve Quote</h4>
      </div>
      <div className="modal-body">
        <u>Warning</u>: Once approved, this quote cannot be changed.
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-green" onClick={_approveQuote}>
          Approve
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => setShowApproveModal(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  ) : (
    []
  );

  const rejectModal = showRejectModal ? (
    <Modal modalClass="quote-approval-modal double-top-margin">
      <div className="modal-header">
        <h4>Reject Quote</h4>
      </div>
      <div className="modal-body">
        Are you sure you want to reject this quote? This cannot be undone.
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-red" onClick={_rejectQuote}>
          Reject
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => setShowRejectModal(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  ) : (
    []
  );

  const deleteModal = showDeleteModal ? (
    <Modal modalClass="quote-approval-modal double-top-margin">
      <div className="modal-header">
        <h4>Delete Quote</h4>
      </div>
      <div className="modal-body">
        Are you sure you want to delete this quote? This cannot be undone. It will remove the quote from all items it is linked to.
      </div>
      <div className="modal-footer">
        <button className="btn btn-sm btn-red" onClick={() => dispatch(procurementActions.deleteQuote(props.quoteId))}>
          Delete
        </button>
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={() => setShowDeleteModal(false)}
        >
          Cancel
        </button>
      </div>
    </Modal>
  ) : (
    []
  );

  return (
    <div className="quote-detail">
      <div className="quote-form">
        {approveModal}
        {rejectModal}
        {deleteModal}
        <div className="form-group">
          <label>Quote Number</label>
          <div>{quote.quoteNumber}</div>
        </div>
        <div className="form-group">
          <label>Vendor</label>
          <FieldFilterableSelect
            id={quote.id}
            field="vendorId"
            backupField="vendorNameNoId"
            canEdit={editable}
            items={vendors}
            value={quote.vendorId == "0" ? quote.vendorNameNoId : quote.vendorId}
            openField
            update={(f, v, i) =>
              dispatch(procurementActions.updateQuote(i, f, v))
            }
          />
        </div>
        <div className="form-group">
          <label>Lead Time</label>
          <FieldInput
            canEdit={editable}
            field="leadTime"
            value={quote.leadTime}
            id={quote.id}
            dataType="string"
            update={_update}
          />
        </div>
        <div className="form-group">
          <label>Price</label>
          <FieldInput
            canEdit={editable}
            field="price"
            value={quote.price}
            id={quote.id}
            dataType="money"
            update={_update}
          />
        </div>
        
        <div className="form-group">
          <label>Documents</label>
          <div style={{ width: "calc(100% - 220px)", maxWidth: '700px' }}>
            {quote.attachments ? (
              <div style={{ marginBottom: "3px" }}>
                {quote.attachments.map((x) => (
                  <DocIcon
                    key={x.id}
                    doc={
                      {
                        id: x.id,
                        filePath: x.filePath,
                        timestamp: x.timestamp,
                      } as IDocumentInfo
                    }
                    delete={(id) => _removeDocument(id)}
                  />
                ))}
              </div>
            ) : (
              []
            )}
            <div style={{ flexBasis: "100%", height: "0" }}></div>
            {editable && (
              <div style={{ display: "block" }}>
                <input
                  type="file"
                  multiple
                  accept=".pdf,.doc,.docx"
                  onChange={_uploadDocuments}
                />
              </div>
            )}
          </div>
        </div>
        
        <div className="form-group">
          <label>Request Notes</label>
          <textarea
            value={requestNotes}
            onChange={(e) => setRequestNotes(e.target.value)}
            rows={5}
            required
          />
        </div>
        <div className="form-group">
          {approvable && isReviewer ? (<>
            <button
              className="btn btn-x-sm btn-green"
              onClick={() => setShowApproveModal(true)}
            >
              Approve
            </button>{" "}
            &nbsp;
            <button
              className="btn btn-x-sm btn-red"
              onClick={() => setShowRejectModal(true)}
            >
              Reject and Lock
            </button>
            &nbsp;
          </>) : []
          }
          {editable && <button
            className="btn btn-x-sm btn-red"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete
          </button>}
        </div>       
      </div>
      <div className="quote-history-logs">
        <EventHistoryLog objectId={quote.id} objectName="ProcurementQuote" />
      </div>
    </div>
  );
};

export const ProcurementQuoteDetailModal = (props: {
  //project: ProjectDetailVm;
  //quote?: ProcurementQuote;
  isReviewer: boolean;
  editable: boolean;
  quoteId: number;
  close: () => void;
  hasSisterQuoteApproved?: boolean;
  //procurementItem: ProcurementItem;
}) => {


  return (
    <Modal modalClass="quote-modal" style={{ display: "flex" }}>
      <div className="modal-header">
        <h4>Quote Details</h4>
      </div>
      <div className="modal-body">
        <ProcurementQuoteDetail
          //procurementItem={props.procurementItem}
          hasSisterQuoteApproved={props.hasSisterQuoteApproved}
          isReviewer={props.isReviewer}
          editable={props.editable}
          //project={props.project}
          //quote={props.quote}
          quoteId={props.quoteId}
        />
      </div>
      <div className="modal-footer">
        <button
          className="btn btn-sm btn-outline-secondary"
          onClick={props.close}
        >
          Close
        </button>
      </div>
    </Modal>
  );
};
