//import { myStore } from '../storeProvider';

export interface User {
  name: string;
  roles: string[];
  rights: string[];
  subscriptions: string[];
}

export const KnownRoles = Object.freeze({
  Admin: "Admin",
  Accounting: "Accounting",
  CoreSuperUser: "Core SuperUser",
  CSGPM: "CSG PM",
  ClientAdmin: "Client Admin",
  ClientPM: "Client PM",
  Vendor: "Vendor",
  Owner: "Owner",
  SiteSuper: "Site Super",
  OwnerLtd: "Owner Limited",
  Legal: "Legal",
  SubsAdmin: "Subs Admin",
});

export const KnownSubscriptions = Object.freeze({
  Basic: "Basic",
  Level1: "Level 1",
  Level2: "Level 2",
});

export const isAuthenticated = (user: any) => user !== undefined;

export const hasRight = (user: any, rights: any) =>
  user ? rights.some((right: any) => user.rights.indexOf(right) !== -1) : false;

export const isUser = (user: any, userName: string) =>
  user ? userName.toUpperCase() == user.email.toUpperCase() : false;

export const hasRole = (user: any, roles: any) => {
  if (user === undefined || user.roles === undefined) return false;
  return roles && roles.length
    ? roles.some((role: any) => user.roles.indexOf(role) !== -1)
    : true;
};

export const isUserById = (user: any, userIds: any) => {
  if (user === undefined) return false;
  // if no userId was passed then that indicates there are no users with explicit authorization
  return userIds && userIds.length
    ? userIds.some((id: any) => user.userId === id)
    : false;
};

export const hasSubscription = (user: any, subscriptions: any) => {
  if (user === undefined) return false;
  return subscriptions && subscriptions.length
    ? subscriptions.some((sub: any) => user.subscriptions.indexOf(sub) !== -1)
    : true;
};
