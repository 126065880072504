import * as React from 'react'
import { connect } from 'react-redux';
import * as AdminStore from '../store/admin'
import { ApplicationState } from '../store';
import { check_phone_format } from '../helpers/formatters';
import { ListItem } from '../interfaces/interfaces';
import { MyAuthElement } from '../auth/Authorization';
import { KnownRoles } from '../auth/auth';
import FilterableSelect from '../components/FilterableSelect';

interface State {
  users: ListItem[],
  userId?: string,
  userName?: string,
  userEmail?: string,
  userPhone?: string,
  userRoles?: string,
  userActivated?: boolean,
  userStatus?: string;
}

interface FormProps {

}

type Props =
  FormProps
  & AdminStore.Store
  & typeof AdminStore.actionCreators

class EditUserForm extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      users: []
    }
  }

  componentDidMount() {
    this._getUsers()
  }

  _getUsers = () => {
    fetch(`api/User/GetAll?activeOnly=false`)
      .then(res => res.json())
      .then(data => {
        this.setState({ users: data })
      })
  }

  _save = () => {
    const modalBody = document.querySelector('div#edit-user-form') as HTMLDivElement
    if (modalBody) {
      const valid = true;

      const email = (modalBody.querySelector('input[name="email"]') as HTMLInputElement).value
      const fullName = (modalBody.querySelector('input[name="fullName"]') as HTMLInputElement).value
      const phone = (modalBody.querySelector('input[name="phone"]') as HTMLInputElement).value
      const roleChkBxs = modalBody.querySelectorAll('#user-roles input[type="checkbox"]:checked');
      const status = (modalBody.querySelector('#user-status input[type="radio"]:checked') as HTMLInputElement).value;

      const roles: string[] = [];
      [].forEach.call(roleChkBxs, (r: HTMLInputElement) => roles.push(r.value))


      if (valid) {
        this.props.editUser(this.state.userId || '', email, fullName,
          phone, roles, status)
      }
    }
  }

  _changeUser = (val: string) => {
    fetch(`api/User/GetInfo?userId=${val}`)
      .then(res => res.json())
      .then(data => {
        this.setState({
          userId: val,
          userEmail: data.email,
          userName: data.fullName,
          userRoles: data.roles,
          userPhone: data.phoneNumber,
          userActivated: data.userActivated,
          userStatus: data.status,
        })
      })
  }

  render() {
    const userOpts = this.state.users && this.state.users.length ?
      this.state.users.map(c => <option key={c.id} value={c.id}>{c.value}</option>) :
      []
    const { userRoles } = this.state;
    return (
      <div>
        <div className="form-header">
          <h4 className="modal-title">Edit User</h4>
        </div>
        <div className="form-body" id="edit-user-form">
          <br />
          <div className='form-group'>
            <label>User</label>
            {/*<select className='form-control' name='clientId'
                            onChange={this._changeUser}>
                            <option value='-1'>Select...</option>
                            {userOpts}
                        </select>*/}
            <FilterableSelect onChange={this._changeUser} items={this.state.users} id='edit_user' defaultVal='' />
          </div>
          <div className='form-group'>
            <label>Contact Email</label>
            <input key={this.state.userEmail} type='email' name='email' className='form-control' defaultValue={this.state.userEmail} />
          </div>
          <div className='form-group'>
            <label>Full Name</label>
            <input key={this.state.userName} name='fullName' className='form-control' defaultValue={this.state.userName} />
          </div>
          <div className='form-group'>
            <label>Phone Number</label>
            <input type='tel' key={this.state.userPhone} name='phone' className='form-control formatted-phone'
              defaultValue={this.state.userPhone} onKeyUp={check_phone_format} />
          </div>
          <div className='form-group' key={this.state.userRoles || 'reset'}
            style={{ border: '1px solid lightgray', borderRadius: '2px', padding: '10px' }}
          >
            <label>Roles</label>
            <br />
            <div id='user-roles' className='flex-columns'>
              <div><input type="checkbox" name="user-role" value="Admin" defaultChecked={userRoles !== undefined && userRoles.indexOf('Admin') !== -1} /> Admin</div>
              <div><input type="checkbox" name="user-role" value="Core SuperUser" defaultChecked={userRoles !== undefined && userRoles.indexOf('Core SuperUser') !== -1} /> Core SuperUser</div>
              <div><input type="checkbox" name="user-role" value="Legal" defaultChecked={userRoles !== undefined && userRoles.indexOf('Legal') !== -1} /> Legal </div>
              <div><input type="checkbox" name="user-role" value="CSG PM" defaultChecked={userRoles !== undefined && userRoles.indexOf('CSG PM') !== -1} /> CSG PM</div>
              <div><input type="checkbox" name="user-role" value="Client Admin" defaultChecked={userRoles !== undefined && userRoles.indexOf('Client Admin') !== -1} /> Client Admin</div>
              <div><input type="checkbox" name="user-role" value="Client PM" defaultChecked={userRoles !== undefined && userRoles.indexOf('Client PM') !== -1} />  Client PM</div>
              <div><input type="checkbox" name="user-role" value="Site Super" defaultChecked={userRoles !== undefined && userRoles.indexOf('Site Super') !== -1} />  Site Super</div>
              <div><input type="checkbox" name="user-role" value="Subs Admin" defaultChecked={userRoles !== undefined && userRoles.indexOf('Subs Admin') !== -1} />  Subs Admin</div>
              <div><input type="checkbox" name="user-role" value="Accounting" defaultChecked={userRoles !== undefined && userRoles.indexOf('Accounting') !== -1} />  Accounting</div>

            </div>
          </div>
          <div className='form-group' key={this.state.userId}
            style={{ border: '1px solid lightgray', borderRadius: '2px', padding: '10px' }}
          >
            <label>Status</label>
            <br />
            <div id='user-status' className='flex-columns'>
              <div><input type="radio" name="user-status" value="A" defaultChecked={this.state.userStatus === 'A'} style={{ marginLeft: '0px' }} /> Active</div>
              <div><input type="radio" name="user-status" value="I" defaultChecked={this.state.userStatus === 'I'} style={{ marginLeft: '0px' }} /> Inactive</div>
              <div><input type="radio" name="user-status" value="T" defaultChecked={this.state.userStatus === 'T'} style={{ marginLeft: '0px' }} /> Termed</div>
            </div>
          </div>
        </div>
        <div className="form-footer">
          <span className="btn btn-sm btn-green" onClick={this._save.bind(this)}>Save</span>
        </div>

      </div>
    )
  }
}

export default connect<AdminStore.Store, typeof AdminStore.actionCreators, FormProps>(
  // @ts-ignore
  (state: ApplicationState) => state.admin,
  AdminStore.actionCreators
)(EditUserForm)