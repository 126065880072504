import * as React from 'react';
import * as UserStore from '../store/user';
import { ApplicationState } from '../store';
import { GlobalMessageBox, MessageBox } from '../components/MessageBox';
import { RecordNotes } from '../components/RecordNotes';
import { VendorVm } from '../interfaces/interfaces';
import { hasRole, KnownRoles } from '../auth/auth';
import { check_phone_format } from '../helpers/formatters';
import { VendorRatings } from './VendorRatings';
import { VendorTrades } from './VendorTrades';
import FilterableSelect from '../components/FilterableSelect';
import { StateDropdown } from '../components/StateDropdown';
import { VendorDivisions } from './VendorDivisions';
import { VendorRegions } from './VendorRegions';
import { SliderCheckbox } from '../components/SliderCheckbox';
import { useSelector, useDispatch } from 'react-redux';
import { VendorMarkets } from './VendorMarkets';

interface Props {
  id?: number,
  info?: VendorVm,
  addCallback?: (d: VendorVm) => void
}

interface State {
  info: VendorVm,
  message?: string
}

export const VendorInfoForm = (props: Props) => {
  //class VendorInfoForm extends React.Component<Props, State> {

  // @ts-ignore
  const [state, setState] = React.useState({ id: 0, active: false, name: '', contactName: '', email: '', address: '', address2: '', city: '', country: '', laborType: '', vendorType: '', phone: '', notes: '', legalNotes: '' } as VendorVm);

  const userStore = useSelector((s: ApplicationState) => s.user);
  const dispatch = useDispatch();

  React.useEffect(() => {
    if (props.info && props.info.id) {
      setState(props.info);
    }
    else {
      _getInfo();
    }
  }, [props.info, props.id])

  const _getInfo = () => {
    const { id } = props;
    if (id) {
      fetch(`api/Vendor/Detail?id=${id}`)
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) setState(data);
          //else setState({ message: data.message })
        })
    }
  }

  const _updateNotes = (e: React.FocusEvent<HTMLTextAreaElement>, action: string) => {
    const vendorId = props.id || props.info ? props.info?.id : 0
    if (vendorId) {
      const body = {
        entityId: vendorId,
        text: e.currentTarget.value
      };
      fetch(`api/Vendor/${action}`, {
        method: 'PUT',
        body: JSON.stringify(body),
        headers: { 'Content-Type': 'application/json' }
      })
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk) {
            if (action === 'Notes') setState({ ...state, notes: data });
            else setState({ ...state, legalNotes: data });
          }
          //else setState({ message: data.message });
        })
    }
  }

  const _handleChange = (e: React.FocusEvent<HTMLInputElement> | React.ChangeEvent<HTMLInputElement> | React.ChangeEvent<HTMLSelectElement>) => {
    if (e.currentTarget.type === 'checkbox') {
      _updateInfo(e.currentTarget.name, (e.currentTarget as HTMLInputElement).checked)
    } else {
      if (e.currentTarget.tagName === 'SELECT' && e.currentTarget.value === '') return;
      _updateInfo(e.currentTarget.name, e.currentTarget.value);
    }
  }

  const _updateInfo = (prop, val) => {
    const vendorId = props.id || props.info ? props.info?.id : 0
    if (vendorId) {
      const model = JSON.parse(JSON.stringify(state)) as VendorVm;
      model[prop] = val;

      fetch(`api/Vendor/Edit?id=${vendorId}`,
        {
          method: 'PUT',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ fieldName: prop, value: val })
        }
      )
        .then(res => Promise.all([res.json()]))
        .then(([data]) => {
          setState(model)
        })
    }
  }

  const _updateRating = (id: number, ratingNumber: number) => {
    fetch(
      `api/Vendor/UpdateVendorRating?id=${id}&rating=${ratingNumber}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
      }
    )
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        fetch(`api/Vendor/Detail?id=${data.vendorId}`)
        .then(res => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          if (resOk){
            setState(data);
            dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Rating updated',
            });
          } 
          //else 
        })
      });
  };

  //const _clearMessage = () => setState({ message: undefined })

  const { id, name, contactName, email, address, address2, city, country, laborType, vendorType, phone, notes, legalNotes } = state;

  const adding = props.addCallback !== undefined;
  const title = adding ? 'Add Vendor' : name;

  const canEdit = adding || hasRole(userStore.user, [KnownRoles.Admin, KnownRoles.CoreSuperUser, KnownRoles.CSGPM]);
  return (
    <div className='vendor-info-form custom-scrollbar'>
      <GlobalMessageBox />
      <h5 className='hide-in-detail'>{title}</h5>
      <hr />
      {adding &&
        <div className='form-group'>
          <div className='inline-label'>Vendor Name</div>
          <input className='form-control' name='name' />
        </div>
      }
      <div className='form-group'>
        <div className='inline-label'>Contact Name</div>
        <input className='form-control' name='contactName' defaultValue={contactName} disabled={!canEdit} onBlur={_handleChange} />
      </div>
      <div className='form-group'>
        <div className='inline-label'>Contact Email</div>
        <input className='form-control' name='email' defaultValue={email} disabled={!canEdit} onBlur={_handleChange} />
      </div>
      <div className='form-group'>
        <div className='inline-label'>Address</div>
        <input className='form-control' name='address' defaultValue={address} disabled={!canEdit} onBlur={_handleChange} style={{ width: 'calc(55% - 110px)' }} />
        <div style={{ width: '30px' }}></div>
        <div className='inline-label' style={{width: '60px'}}>City</div>
        <input className='form-control' name='city' defaultValue={city} disabled={!canEdit} onBlur={_handleChange} style={{ width: 'calc(40% - 90px)' }} />
      </div>     
      <div className='form-group'>        
        <div className='inline-label'>State</div>
        <StateDropdown id='vendor-state' defaultValue={state.state} onChange={(id) => _updateInfo('state', id)} style={{ width: 'calc(55% - 110px)' }} />
        {/*<input className='form-control' name='state' defaultValue={state} disabled={!canEdit} onBlur={_handleChange} style={{ width: 'calc(40% - 60px)' }} />*/}
        <div style={{ width: '30px' }}></div>
        <div className='inline-label' style={{ width: '60px' }}>Country</div>
        <input className='form-control' name='country' defaultValue={country} disabled={!canEdit} onBlur={_handleChange} style={{width: 'calc(40% - 90px)'}} />
      </div>
      <div className='form-group'>
        <div className='inline-label'>Phone</div>
        <input type='tel' className='form-control' name='phone' defaultValue={phone} disabled={!canEdit} onBlur={_handleChange} onKeyUp={check_phone_format} />
      </div>
      <div className='form-group'>
        <div className='inline-label'>Labor Type</div>
        <select key={laborType || 'labor'} className='form-control' name='laborType' defaultValue={laborType} disabled={!canEdit} onBlur={_handleChange} style={{ width: 'calc(50% - 140px)' }}>
          <option className='form-control' value=""></option>
          <option className='form-control' value="Open Shop">Open Shop</option>
          <option className='form-control' value="Merit">Merit</option>
          <option className='form-control' value="Non-Union">Non-Union</option>
          <option className='form-control' value="Union">Union</option>
        </select>
        <div style={{ width: '30px' }}></div>
        <div className='inline-label' style={{ width: '100px' }}>Vendor Type</div>
        <select key={vendorType || 'type'} className='form-control' name='vendorType' defaultValue={vendorType} disabled={!canEdit} onBlur={_handleChange} style={{ width: 'calc(50% - 140px)' }}>
          <option className='form-control' value=''></option>
          <option className='form-control' value='Subcontractor'>Subcontractor</option>
          <option className='form-control' value='Material Supplier'>Material Supplier</option>
          <option className='form-control' value='Both'>Both</option>
        </select>
      </div>
      <div className='form-group'>
        <div className='inline-label'>Trades</div>
        <VendorTrades vendorId={id} trades={state.trades} />
      </div>
      <div className='form-group'>
        <div className='inline-label'>Markets</div>
        <VendorMarkets vendorId={id} markets={state.markets} />
      </div>
      {/*<div className='form-group'>*/}
      {/*  <div className='inline-label'>Company Divisions</div>*/}
      {/*  <VendorDivisions vendorId={id} divisions={state.divisions} />*/}
      {/*</div>*/}
      <div className='form-group'>
        <div className='inline-label'>Regions</div>
        <VendorRegions vendorId={id} regions={state.regions} />
      </div>
      {!adding && <div className='form-group'>
        <div className='inline-label'>Active</div>
        <SliderCheckbox selected={state.active || false} fieldName='active' dataId={state.id} onChange={_handleChange} />
      </div>}
      {!adding && <div className='form-group'>
        <div className='inline-label'>Legal Restricted</div>
        <SliderCheckbox selected={state.legalRestricted || false} fieldName='legalRestricted' dataId={state.id} onChange={_handleChange} disabled={!hasRole(userStore.user, [KnownRoles.Legal])} />
      </div>}
      {!adding && <RecordNotes notes={notes} label='Notes' update={(e) => _updateNotes(e, 'Notes')} canEdit={canEdit} />}
      {!adding && <RecordNotes notes={legalNotes} label='Legal Notes' update={(e) => _updateNotes(e, 'LegalNotes')} canEdit={hasRole(userStore.user, [KnownRoles.Legal])} />}
      {!adding && <hr />}
      {!adding && <VendorRatings vendor={state} user={userStore.user} onUpdate={_updateRating} />}
    </div>
  )
}


//export default connect<UserStore.Store, any, MyProps>(
//  // @ts-ignore
//  (state: ApplicationState) => state.user,
//  {}
//)(VendorInfoForm)