import * as React from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router";
import { hasRole, isUserById, KnownRoles } from "../auth/auth";
import UserIdAuthElement from "../auth/UserIdAuthElement";
import DocIcon from "../components/DocIcon";
import { FileDownloader } from "../components/FileDownloader";
import FilterableSelect from "../components/FilterableSelect";
import { JoinedList } from "../components/JoinedList";
import Modal from "../components/Modal";
import PointedPopup from "../components/PointedPopup";
import { TransferRecordModal } from "../components/TransferRecordModal";
import { formatDate, formatDateTime, toMoney } from "../helpers/formatters";
import {
	ListItem,
	ProjectTeamMember,
	RFI,
	RFIDetailVm,
	RFIResponse,
	RFIResponseStatus,
	RFIStatus,
	TransferRecordType,
	UserInfo,
} from "../interfaces/interfaces";
import { StandardGridLoader } from "../loaders/StandardGridLoader";
import { getTeamMembers } from "../services/project";
import { ApplicationState } from "../store";
import { ReissueComponent } from "./ReissueComponent";
import RFICommentStream from "./RFICommentStream";
import { RFIReissueHistory } from "./RFIReissueHistory";
import { RFIResponseCard } from "./RFIResponseCard";
import RFISwapReviewerModal from "./RFISwapReviewerModal";
import * as cx from "classnames";
import { Link } from "react-router-dom";
import { MessageBox } from "../components/MessageBox";
import { Loader } from "../components/Loader";
import { FilterableSelectContainer } from "src/components/FilterableSelectContainer";
import $ from "jquery";

interface Props {
	rfiId: number;
	refreshCallback?: () => void;
	deleteCallback?: () => void;
}

export const RFIDetail = (props: Props) => {
	const [record, setRecord] = React.useState({} as RFIDetailVm);
	const [projectManagerId, setProjectManagerId] = React.useState("" as string);
	const [loading, setLoading] = React.useState(true);
	const [message, setMessage] = React.useState(undefined as string | undefined);
	const [selectedDisciplines, setSelectedDisciplines] = React.useState(
		[] as string[]
	);
	const [showAddReviewer, setShowAddReviewer] = React.useState(false);
	const [removeReviewerSelectedId, setRemoveReviewerSelectedId] =
		React.useState(undefined as string | undefined);
	const [showExternalEmailDialog, setShowExternalEmailDialog] =
		React.useState(false);
	const [showSketchRefLine, setShowSketchRefLine] = React.useState(false);
	const [showClosingCommentBox, setShowClosingCommentBox] =
		React.useState(false);
	const [showComments, setShowComments] = React.useState(true);
	const [showCCedUsersDialog, setShowCCedUsersDialog] = React.useState(false);
	const [reviewerHoveredId, setReviewerHoveredId] = React.useState(
		undefined as string | undefined
	);
	const [swapReviewerObject, setSwapReviewerObject] = React.useState({
		reviewer: "",
		discipline: "",
	});
	const [showSwapModal, setShowSwapModal] = React.useState(false);
	const [showTransferRecordModal, setShowTransferRecordModal] =
		React.useState(false);
	const [openResponses, setOpenResponses] = React.useState([] as RFIResponse[]);
	const [showResponseModal, setShowResponseModal] = React.useState(false);
	const [showCancelModal, setShowCancelModal] = React.useState(false);

	const [projectTeamMembers, setProjectTeamMembers] = React.useState(
		[] as ListItem[]
	);
	const [bodyScroll, setBodyScroll] = React.useState(false);
	const reviewerRef = React.useRef(null);
	const disciplineRef = React.useRef(null);

	const globalStore = useSelector((s: ApplicationState) => s.global);
	const userStore = useSelector((s: ApplicationState) => s.user);
	const history = useHistory();

	const { user } = userStore;
	const isAdmin = hasRole(user, [KnownRoles.Admin]);

	const _addReviewer = () => {
		const { users } = globalStore;
		const select = document.querySelector(
			".new-reviewer-section #new-reviewer-select"
		) as HTMLDivElement;
		const reviewer = select.querySelector(
			'input[name="new-reviewer-select"]'
		) as HTMLInputElement;
		const discipline = document.querySelector(
			'.new-reviewer-section #new-discipline-select input[name="new-discipline-select"]'
		) as HTMLInputElement;
		const useridList = users.map((user) => user.userId);
		const availableDisciplines = getDisciplineOpts().filter(
			(discipline) => !selectedDisciplines.includes(discipline.value)
		);
		const isAvailableDiscipline = availableDisciplines.some(
			(x) => x.value === discipline.value
		);

		if (useridList.includes(reviewer.value) && isAvailableDiscipline) {
			fetch(
				`api/RFI/Reviewer?id=${record.id}&reviewerId=${reviewer.value}&discipline=${discipline.value}`,
				{
					method: "POST",
				}
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setRecord(data);
						setMessage("Saved");
						setShowAddReviewer(false);
					} else {
						setMessage(data.message);
					}
				});
		} else {
			setMessage("Error: Please enter a valid Reviewer or Discipline.");
		}
	};

	const sendRFI = () => {
		setLoading(true);
		fetch(`api/RFI/Update?id=${record.id}`, {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({ fieldname: 'status', value: RFIStatus.AwaitingResponse })
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				setLoading(false);
				if (resOk) {
					setRecord(data);
					
				} else {
					setMessage(data.message);
				}
			})
			.catch(error => console.error('Error:', error));
	}


	const _getDetails = (id: number) => {
		fetch(`api/RFI/Detail?id=${id}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setRecord(data.rfi);
					setProjectManagerId(data.projectManagerId);
					setLoading(false);
				} else {
					setMessage(data.message);
				}
			});
	};

	const _addResponse = (e: React.MouseEvent<HTMLButtonElement>) => {
		setLoading(true);
		const button = e.currentTarget;
		button.disabled = true;
		const responseTextArea = document.getElementById(
				"new-rfi-response"
			) as HTMLTextAreaElement,
			response = responseTextArea.value;
		const fileInput = document.getElementById(
				"new-response-upload"
			) as HTMLInputElement,
			files = fileInput.files;
		const sketchRefChk = document.querySelector(
			'.sketch-ref-chk > input[type="checkbox"'
		) as HTMLInputElement;
		const sketchRef = sketchRefChk === null ? false : sketchRefChk.checked;

		const form = new FormData();
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; ++i)
				form.append(`files`, files[i], files[i].name);
		}
		form.append(
			"json",
			JSON.stringify({
				rfiId: record.id,
				response: response,
				isSketchRef: sketchRef,
			})
		);
		fetch(`api/RFI/Response`, {
			method: "POST",
			body: form,
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					if (props.refreshCallback) {
						// We don't know that adding a response takes it off the user's queue so we'll just call refreshCallback
						props.refreshCallback();
					} else {
						setShowResponseModal(false);
						setRecord(data);
					}
				} else {
					setMessage(data.message);
					button.disabled = false;
				}
				setLoading(false);
			});
	};

	const _addResponseComment = (id: number, comment: string) => {
		fetch(`api/RFI/ResponseComment`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				rfiId: record.id,
				responseId: id,
				comment: comment,
			}),
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setRecord(data);
				else setMessage(data.message);
			});
	};

	const _addRFIComment = (comment: string) => {
		fetch(`api/RFI/Comment`, {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				rfiId: record.id,
				responseId: 0,
				comment: comment,
			}),
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setRecord(data);
				else setMessage(data.message);
			});
	};

	const _closeResponse = (id: number, approved: boolean, reason?: string) => {
		setLoading(true);
		fetch(
			`api/RFI/CloseResponse?id=${id}&approved=${approved}&rfiId=${record.id}&reason=${reason}`,
			{ method: "PUT" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setRecord(data);
					setLoading(false);
				} else setMessage(data.message);
			});
	};

	const _removeReviewer = () => {
		if (removeReviewerSelectedId) {
			fetch(
				`api/RFI/Reviewer?id=${record.id}&reviewerId=${removeReviewerSelectedId}`,
				{ method: "DELETE" }
			)
				.then((res) => Promise.all([res.ok, res.json()]))
				.then(([resOk, data]) => {
					if (resOk) {
						setRecord(data);
						setRemoveReviewerSelectedId(undefined);
					} else setMessage(data.message);
				});
		}
	};

	const _approve = () => {
		const textArea = document.getElementById(
				"rfi-approval-comment"
			) as HTMLTextAreaElement,
			comment = textArea.value,
			cost = document.getElementById("cost_impact") as HTMLInputElement,
			schedule = document.getElementById("schedule_impact") as HTMLInputElement;

		//if (!comment || comment === '') this.setState({ message: 'Error: You must provide a closing comment' })
		setLoading(true);
		fetch(
			`api/RFI/Approve?id=${record.id}&comment=${comment}&cost=${cost.value}&schedule=${schedule.value}`,
			{ method: "PUT" }
		)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					if (props.deleteCallback) {
						// After approval we know it won't need to be a in workstream so we'll just use the deleteCallback
						props.deleteCallback();
					} else setRecord(data);
				} else setMessage(data.message);
			});
		setLoading(false);
	};

	const _editCCedUsers = (ids: ListItem[]) => {
		const userIds = ids.map((x) => `userIds=${x.id}`).join("&");

		fetch(`api/rfi/editccedusers?id=${record.id}&${userIds}`, {
			method: "PUT",
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setRecord(data);
				else setMessage(data.message);
			});
	};

	const _cancel = () => {
		setLoading(true);
		fetch(`api/RFI/Cancel?id=${record.id}`, { method: "PUT" })
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					if (props.deleteCallback) {
						props.deleteCallback();
					} else {
						history.push(`/project/${record.projectId}#rfi`);
					}
				} else setMessage(data.message);
			});
	};

	const _sendExternal = () => {
		const email = (
			document.querySelector("#send-external-email > input") as HTMLInputElement
		).value;
		fetch(`api/RFI/SendExternal?id=${record.id}&email=${email}`)
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) {
					setShowExternalEmailDialog(false);
					setMessage("Email sent");
				} else setMessage(data.message);
			});
	};

	const _addDocs = (e: React.ChangeEvent<HTMLInputElement>) => {
		setLoading(true);
		const input = e.currentTarget,
			files = input.files;
		const form = new FormData();
		if (files && files.length > 0) {
			for (let i = 0; i < files.length; ++i)
				form.append("files", files[i], files[i].name);
		}

		fetch(`api/RFI/AddDocs?id=${record.id}`, {
			method: "PUT",
			body: form,
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				input.value = "";
				if (resOk) setRecord(data);
				else setMessage(data.message);

				setLoading(false);
			});
	};

	const _updateResponse = (form: FormData, button: HTMLButtonElement) => {
		setLoading(false);
		fetch(`api/RFI/Response?id=${record.id}`, {
			method: "PUT",
			body: form,
		})
			.then((res) => Promise.all([res.ok, res.json()]))
			.then(([resOk, data]) => {
				if (resOk) setRecord(data);
				else setMessage(data.message);

				setLoading(false);
				button.disabled = false;
			});
	};

	const _toggleAddReviewer = () => setShowAddReviewer(!showAddReviewer);

	const _toggleSketchRefLine = (e?: React.ChangeEvent<HTMLInputElement>) => {
		if (e) {
			setShowSketchRefLine(e.currentTarget.value.length !== 0);
		} else setShowSketchRefLine(showSketchRefLine);
	};

	const _toggleClosingCommentBox = () =>
		setShowClosingCommentBox(!showClosingCommentBox);

	const _toggleComments = () => setShowComments(!showComments);

	const _toggleCCedUsersDialog = () =>
		setShowCCedUsersDialog(!showCCedUsersDialog);

	const _toggleExternalEmail = () =>
		setShowExternalEmailDialog(!showExternalEmailDialog);

	const _toggleHoveredReviewer = (id?: string) => setReviewerHoveredId(id);

	const _toggleRemoveReviewerId = (id?: string) =>
		setRemoveReviewerSelectedId(id);

	const _onSwap = (discipline: string, reviewer: string) => {
		const useridList = globalStore.users.map((user) => user.userId);
		const isAvailableDiscipline = getDisciplineOpts().some(
			(x) => x.value === discipline
		);
		const currentReviewer = swapReviewerObject;

		if (record.reviewers.some((x) => x.userId === reviewer)) {
			setMessage(
				"Error: Duplicate reviewer found. Please make sure each discipline has a unique reviewer assigned."
			);
			return;
		}

		setLoading(true);

		if (
			discipline &&
			reviewer &&
			useridList.includes(reviewer) &&
			isAvailableDiscipline
		) {
			if (
				reviewer === currentReviewer.reviewer &&
				discipline !== currentReviewer.discipline
			) {
				fetch(
					`api/RFI/UpdateReviewerDiscipline?id=${record.id}&reviewerId=${reviewer}&discipline=${discipline}`,
					{
						method: "POST",
					}
				)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							setRecord(data);
							setMessage("Saved");
						} else {
							setMessage(data.message);
						}
						setLoading(false);
						setShowSwapModal(false);
					});
			} else {
				fetch(
					`api/RFI/SwapReviewer?id=${record.id}&oldReviewerId=${currentReviewer.reviewer}&newReviewerId=${reviewer}&discipline=${discipline}`,
					{
						method: "POST",
					}
				)
					.then((res) => Promise.all([res.ok, res.json()]))
					.then(([resOk, data]) => {
						if (resOk) {
							if (resOk) {
								setRecord(data);
								setMessage("Saved");
							} else {
								setMessage(data.message);
							}
							setLoading(false);
							setShowSwapModal(false);
						}
					});
			}
		} else {
			setMessage("Error: Please enter a valid Reviewer or Discipline.");
		}
	};

	React.useEffect(() => {
		setRecord({} as RFIDetailVm);
		_getDetails(props.rfiId);
	}, [props.rfiId]);

	React.useEffect(() => {
		setSelectedDisciplines(
			record.reviewers
				? record.reviewers.map((reviewer) => reviewer.discipline)
				: []
		);
	}, [record.reviewers]);

	React.useEffect(() => {
		setOpenResponses(
			record.responses
				? record.responses.filter(
						(x) => x.status === RFIResponseStatus.Submitted
				  )
				: []
		);
	}, [record.responses]);

	React.useEffect(() => {
		const run = async () => {
			if (record.projectId) {
				const response = await getTeamMembers(record.projectId);
				if (response) {
					if (response.status === 200) {
						setProjectTeamMembers(response.data);
					} else {
						setMessage(response.data.message);
					}
				} else {
					setMessage(
						"Error: There was an error loading the project's team members"
					);
				}
			}
		};
		run();
	}, [record.projectId]);

	const handleTop = React.useCallback(
		(ref: HTMLDivElement | null) => {
			$(".rfi-detail.custom-scrollbar").click();
			ref?.querySelector("input")?.blur();
			if (ref) {
				return ref.getBoundingClientRect().top + ref.offsetHeight + "px";
			}
			return "";
		},
		[bodyScroll, reviewerRef.current, disciplineRef.current, showAddReviewer]
	);

	const handleWidth = React.useCallback(
		(ref: HTMLDivElement | null) => {
			if (ref) {
				return (ref.children[1] as HTMLDivElement)?.offsetWidth + "px";
			}
			return "";
		},
		[bodyScroll, reviewerRef.current, disciplineRef.current, showAddReviewer]
	);

	let status = "",
		statusClass = "status-card";

	switch (record.status) {
		case RFIStatus.Draft:
			status = 'Draft';
			statusClass += " awaiting";
			break;
		case RFIStatus.AwaitingResponse:
			status = `Awaiting Response`;
			statusClass += " awaiting";
			break;
		case RFIStatus.Answered:
			status = "Answered";
			statusClass += " answered";
			break;
		case RFIStatus.Accepted:
			status = "Accepted";
			statusClass += " approved";
			break;
		case RFIStatus.Reissued:
			status = "Reissued";
			statusClass += " reissued";
			break;
		case RFIStatus.ReissueRequested:
			status = "Reissue Requested";
			statusClass += " reissued";
			break;
		case RFIStatus.Canceled:
			status = "Canceled";
			statusClass += " canceled";
			break;
	}

	const renderRFIResponses = (
		responses: RFIResponse[],
		isSubmitter: boolean
	) => {
		const responseCards = responses
			? responses.map((x) => {
					return (
						<div key={x.id}>
							<RFIResponseCard
								response={x}
								isSubmitter={isSubmitter}
								addComment={_addResponseComment}
								closeResponse={_closeResponse}
								updateResponse={_updateResponse}
								rfiStatus={record.status}
							/>
						</div>
					);
			  })
			: [];

		return <div className="rfi-responses">{responseCards}</div>;
	};

	const renderAddResponseSection = (isReviewer: boolean) => {
		if (!isReviewer || record.status !== RFIStatus.AwaitingResponse) return <div></div>;

		return (
			<div className="rfi-new-response-section">
				<button
					className="btn btn-sm btn-green"
					onClick={() => setShowResponseModal(true)}
				>
					Provide Official Response
				</button>
			</div>
		);
	};

	const renderCancelModal = () => {
		return (
			<Modal dialogStyle={{ minWidth: "400px" }} style={{ display: "flex" }}>
				<div className="modal-body">
					<h5>Cancel RFI</h5>
					<b>Caution: This action is irreversible</b>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-outline-danger" onClick={_cancel}>
						Cancel
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => setShowCancelModal(false)}
					>
						Close
					</button>
				</div>
			</Modal>
		);
	};

	const renderAddReviewerLine = (isSubmitter: boolean) => {
		const { user } = userStore;
		const userOpts = globalStore.users
			.filter(
				(x) =>
					record.reviewers &&
					record.reviewers.findIndex((y) => y.userId === x.userId) === -1
			)
			.map((x) => new ListItem(x.userId, x.fullName));
		const isSubmitterOrReviewer =
			isSubmitter ||
			(user
				? record.reviewers
					? record.reviewers.findIndex((x) => x.userId === user.userId) !== -1
					: false
				: false);

		return (
			<React.Fragment>
				<div>
					{(record.status <= RFIStatus.Answered) &&
					isSubmitterOrReviewer ? (
						<button
							type="button"
							style={{ fontSize: "12px", padding: "0.25rem 0.25rem" }}
							className="btn btn-sm btn-background-hover"
							onClick={_toggleAddReviewer}
						>
							<i className="fas fa-plus"></i> &nbsp; Add Reviewer
						</button>
					) : (
						[]
					)}
				</div>
				{showAddReviewer ? (
					<div className="new-reviewer-section">
						<FilterableSelectContainer
							top={handleTop(disciplineRef.current)}
							ulWidth={handleWidth(disciplineRef.current)}
							ref={disciplineRef}
						>
							<span>Discipline</span>
							<FilterableSelect
								id="new-discipline-select"
								items={getDisciplineOpts().filter(
									(discipline) =>
										!selectedDisciplines.includes(discipline.value)
								)}
								openField={false}
							/>
						</FilterableSelectContainer>
						<FilterableSelectContainer
							top={handleTop(reviewerRef.current)}
							ulWidth={handleWidth(reviewerRef.current)}
							ref={reviewerRef}
						>
							<span>Reviewer</span>
							<FilterableSelect
								id="new-reviewer-select"
								items={userOpts}
								openField={false}
							/>
						</FilterableSelectContainer>
						<button className="btn btn-sm btn-blue" onClick={_addReviewer}>
							Add
						</button>
					</div>
				) : (
					[]
				)}
			</React.Fragment>
		);
	};

	const renderClosingLine = (record: RFI, isSubmitter: boolean) => {
		const rfiDocs =
			record.responseDocuments &&
			record.responseDocuments.map((x) => <DocIcon key={x.id} doc={x} />);

		if (
			record.status === RFIStatus.Accepted ||
			record.status === RFIStatus.Reissued ||
			record.status === RFIStatus.ReissueRequested
		) {
			// approved
			return (
				<div className="rfi-closing-line approved">
					<span className="col-sm-9">
						Accepted on {formatDateTime(record.approvedTimestamp)}
					</span>
					<div className="rfi-doc-icons">{rfiDocs}</div>
				</div>
			);
		}
		if (
			isSubmitter &&
			record.status === RFIStatus.Answered
		) {
			// answered but not yet approved
			return (
				<div>
					<div className="rfi-closing-line">
						<span className="col-sm-8" style={{ fontSize: "1.6em" }}>
							{new Date().toDateString()}
						</span>
						<button
							className="btn btn-sm btn-success"
							onClick={_toggleClosingCommentBox}
						>
							Accept
						</button>
						<div className="rfi-doc-icons">{rfiDocs}</div>
					</div>
					{showClosingCommentBox && renderNewClosingComment()}
				</div>
			);
		}

		return <div></div>;
	};

	const renderNewClosingComment = () => {
		return (
			<Modal>
				<div className="modal-header">
					<h4>Accept RFI</h4>
				</div>
				<div className="modal-body">
					<b>
						Accepting the RFI responses will lock the RFI for editing. You will
						have to re-issue this RFI in order to further discussion if you
						close the RFI too early.
					</b>
					<div className="closing-comment-box">
						<div style={{ display: "flex" }}>
							<div className="form-group">
								<label>Cost Impact</label>
								<div>
									<input
										type="number"
										className="form-control"
										id="cost_impact"
										dir="rtl"
									/>{" "}
									USD
								</div>
								<br />
								<label>Schedule Impact</label>
								<div>
									<input
										type="number"
										className="form-control"
										id="schedule_impact"
										dir="rtl"
									/>{" "}
									Days
								</div>
							</div>
							<div className="form-group" style={{ flexGrow: 1 }}>
								<label>Closing Comments</label>
								<textarea
									id="rfi-approval-comment"
									className="form-control"
									rows={5}
								></textarea>
							</div>
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-success" onClick={_approve}>
						Accept
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_toggleClosingCommentBox}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const renderCCedUsersDialog = () => {
		return (
			<Modal>
				<div className="modal-body">
					<JoinedList
						title="CCed Users"
						availableItems={projectTeamMembers.filter(
							(x) => record.ccedUsers.findIndex((y) => y.userId === x.id) === -1
						)}
						selectedItems={record.ccedUsers.map(
							(x) => new ListItem(x.userId, x.userFullName)
						)}
						onChange={_editCCedUsers}
					/>
					<br />
				</div>
				<div className="modal-footer">
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={_toggleCCedUsersDialog}
					>
						Close
					</button>
				</div>
			</Modal>
		);
	};

	const renderExternalEmailDialog = () => {
		return (
			<div id="send-external-email">
				<input className="form-control" />
				<button
					className="btn btn-sm btn-blue fas fa-paper-plane"
					onClick={_sendExternal}
				></button>
			</div>
		);
	};

	const renderRemoveReviewerModal = () => {
		return (
			<Modal>
				<div className="modal-header">
					<h4>Remove Reviewer</h4>
				</div>
				<div className="modal-body">
					Are you sure you want to remove this reviewer from this RFI?
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-danger" onClick={_removeReviewer}>
						Remove
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => _toggleRemoveReviewerId(undefined)}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const renderResponseModal = () => {
		const reviewer = record.reviewers.find((x) => x.userId === user?.userId);
		return (
			<Modal>
				<div className="modal-header">
					<h4>Add Official Response</h4>
				</div>
				<div className="modal-body">
					<div className="form-group">
						<label style={{ display: "inline-block", width: "150px" }}>
							<b>Reviewer </b>
						</label>
						<label>{reviewer?.userFullName}</label>
					</div>
					<div className="form-group">
						<label style={{ display: "inline-block", width: "150px" }}>
							<b>Discipline </b>
						</label>
						<label>{reviewer?.discipline}</label>
					</div>
					<div className="form-group">
						<label style={{ display: "inline-block", width: "150px" }}>
							<b>Response </b>
						</label>
						<div
							style={{ display: "inline-table", width: "calc(100% - 150px)" }}
						>
							<textarea
								id="new-rfi-response"
								className="form-control"
								rows={2}
								style={{ marginBottom: "6px" }}
							></textarea>
							<input
								type="file"
								multiple
								accept="application/pdf"
								id="new-response-upload"
								onChange={_toggleSketchRefLine}
								style={{ marginBottom: "6px" }}
							/>
							{showSketchRefLine && (
								<div className="sketch-ref-chk">
									<span>Sketch Reference?</span> &nbsp;{" "}
									<input type="checkbox" />
								</div>
							)}
						</div>
					</div>
				</div>
				<div className="modal-footer">
					<button className="btn btn-sm btn-green" onClick={_addResponse}>
						Submit
					</button>
					<button
						className="btn btn-sm btn-outline-secondary"
						onClick={() => setShowResponseModal(false)}
					>
						Cancel
					</button>
				</div>
			</Modal>
		);
	};

	const awaitingUserResponse = () => {
		const { reviewers, responses } = record;
		// if nothing is loaded, return false
		if (!reviewers || !responses || !user) return false;
		// if the user is not in the list of reviewers, return false
		if (reviewers.findIndex((x) => x.userId === user.userId) === -1) {
			return false;
		}
		// if the user has a non-rejected response, return false
		if (
			responses.findIndex((x) => x.status !== RFIResponseStatus.Rejected && x.userId === user.userId) !==
			-1
		) {
			return false;
		}

		return true;
	};

	const isActiveReviewer = user ? awaitingUserResponse() : false;
	const isSubmitter = user ? record.userId === user.userId : false,
		isPM = user ? projectManagerId === user.userId : false,
		reviewers = record.reviewers
			? record.reviewers.map((x) => {
					return (
						<div
							className="rfi-reviewer-line"
							key={x.userId}
							onMouseEnter={() => _toggleHoveredReviewer(x.userId)}
							onMouseLeave={() => _toggleHoveredReviewer()}
						>
							{x.userFullName} ({x.discipline}){" "}
							{reviewerHoveredId === x.userId &&
							// If it is the owner, pm, or a reviewer on the RFI
							(isSubmitter ||
								isPM ||
								record.reviewers.some((r) => r.userId === user?.userId)) &&
							!openResponses.some((response) => {
								return response.userId == x.userId;
							}) ? (
								<span
									title="Swap Reviewer"
									className="fas fa-exchange-alt"
									onClick={() => {
										setShowSwapModal(true);
										setSwapReviewerObject({
											reviewer: x.userId,
											discipline: x.discipline,
										});
									}}
								></span>
							) : (
								[]
							)}
							{record.reviewers.length > 1 &&
							reviewerHoveredId === x.userId &&
							(isSubmitter || isPM) &&
							!openResponses.some((response) => {
								return response.userId == x.userId;
							}) ? (
								<span
									className="fas fa-times"
									onClick={() => _toggleRemoveReviewerId(x.userId)}
								></span>
							) : (
								[]
							)}
						</div>
					);
			  })
			: [];

	return record && record.id ? (
		<div
			onScroll={() => setBodyScroll(!bodyScroll)}
			className="rfi-detail custom-scrollbar"
			key={props.rfiId}
		>
			<MessageBox
				message={message}
				clearMessage={() => setMessage(undefined)}
			/>
			<Loader loading={loading} />
			<div className="rfi-top-row">
				<span style={{ marginTop: "10px" }}>{record.rfiNumber}</span>
				<div className={statusClass}>{status}</div>
				{/*add a send button for the draft*/}
				

				{/* 
                         @ts-ignore */}
				{record.status === RFIStatus.Draft && (
					<UserIdAuthElement allowedUserIds={[record.userId, projectManagerId]}>
						<span
							className="btn btn-sm btn-background-hover fas fa-send"
							onClick={sendRFI}
							title="Send"
						></span>
					</UserIdAuthElement>
				)}
				<UserIdAuthElement allowedUserIds={[record.userId, projectManagerId]}>
					<span
						className="btn btn-sm btn-background-hover fas fa-users"
						onClick={_toggleCCedUsersDialog}
						title="Edit CCed Users"
					></span>
				</UserIdAuthElement>
				
				<span
					className="btn btn-sm btn-background-hover fas fa-envelope"
					onClick={_toggleExternalEmail}
					title="Forward RFI to external emails"
				></span>
				{showExternalEmailDialog && renderExternalEmailDialog()}
				{record.status === RFIStatus.Draft || record.status === RFIStatus.AwaitingResponse ? (
					//@ts-ignore
					<UserIdAuthElement allowedUserIds={[record.userId, projectManagerId]}>
						<span
							className="far fa-trash-alt btn-background-hover btn btn-sm"
							title="Cancel this RFI"
							onClick={() => setShowCancelModal(true)}
						></span>
					</UserIdAuthElement>
				) : (
					[]
				)}
				{record.status === RFIStatus.Accepted && (
					//@ts-ignore
					<UserIdAuthElement allowedUserIds={[record.userId, projectManagerId]}>
						<ReissueComponent
							rfi={record}
							onSuccess={() => _getDetails(record.id)}
						/>
					</UserIdAuthElement>
				)}
				{/*<span*/}
				{/*    className="fas fa-print btn btn-sm btn-background-hover"*/}
				{/*    onClick={_printPage}*/}
				{/*></span>*/}
				<PointedPopup
					popupClass="gray"
					defaultEle={
						<span className="btn btn-sm fas btn-background-hover fa-download"></span>
					}
				>
					<div style={{ display: "flex" }}>
						<div style={{ padding: "2px 5px", borderRight: "1px solid white" }}>
							<FileDownloader
								actionPath="api/Report/RFIPdf"
								queryParams={{ id: record.id, detailed: false }}
								fileType="PDF"
							/>
							<div>
								<small>Summary</small>
							</div>
						</div>
						<div style={{ padding: "2px 5px" }}>
							<FileDownloader
								actionPath="api/Report/RFIPdf"
								queryParams={{ id: record.id, detailed: true }}
								fileType="PDF"
							/>
							<div>
								<small>Detailed</small>
							</div>
						</div>
					</div>
				</PointedPopup>
				{(record.status === RFIStatus.AwaitingResponse ||
					record.status === RFIStatus.Answered) &&
				isAdmin ? (
					<span
						className="fas fa-exchange-alt btn-background-hover btn btn-sm"
						title="Transfer this rfi"
						onClick={() => setShowTransferRecordModal(true)}
					></span>
				) : (
					[]
				)}
			</div>
			<div className="rfi-body mobile-full-width">
				<h3 style={{ margin: "6px 0px" }}>{record.subject}</h3>
				{record.reissueId && record.reissuedToInfo && (
					<div className="reissue-banner">
						This RFI was reissued on {formatDate(record.reissueTimestamp)}. You
						can view the new RFI{" "}
						<Link to={`rfi/${record.reissueId}`}>here</Link>.
					</div>
				)}
				{record.reissuedFromId && record.reissuedFromInfo && (
					<div className="reissue-banner">
						This RFI is a reissue of {record.reissuedFromInfo.value}. You can
						view the condensed history below. For a full history view the
						original RFI <Link to={`/rfi/${record.reissuedFromId}`}>here</Link>.
					</div>
				)}
				{record && record.id ? (
					<div className="rfi-detail-info">
						<div>
							<b>Owner:</b> {record.userFullName}
						</div>
						<div>
							<b>Submitted:</b> {formatDateTime(record.date)}
						</div>
						<div>
							<b>Due:</b> {formatDateTime(record.dueDate)}
						</div>
						<div>
							<b>Reviewers:</b>{" "}
							<div style={{ display: "inline-table" }}>
								{reviewers}
								{renderAddReviewerLine(isSubmitter)}
							</div>
						</div>
						<div className="impact-details">
							<div>
								<b>Discipline(s) Impacted:</b> {selectedDisciplines.join(", ")}
							</div>
							{record.scheduleImpact ? (
								<div>
									<b>Schedule Impact:</b> {record.scheduleImpact}{" "}
									{record.scheduleImpact > 1 ? "days" : "day"}
								</div>
							) : (
								[]
							)}
							{record.costImpact ? (
								<div>
									<b>Cost Impact:</b> {toMoney(record.costImpact, 2)}
								</div>
							) : (
								[]
							)}
						</div>
						{record.drawingRef && record.drawingRef.length && (
							<div>
								<b>Drawing Reference:</b> {record.drawingRef}
							</div>
						)}
					</div>
				) : (
					[]
				)}

				<div className="rfi-question">
					<div className="response-name-timestamp">
						<span>
							{record.creatorName} - {formatDateTime(record.date)}
						</span>
					</div>
					{record.description && (
						<div className="rfi-response-body">
							<div
								className={cx(
									record.questionDocuments.length !== 0 &&
										"response-text-with-documents"
								)}
							>
								{record.description}
							</div>
							{record.questionDocuments && record.questionDocuments.length ? (
								<div className="rfi-doc-icons">
									{record.questionDocuments.map((x) => (
										<DocIcon doc={x} />
									))}
								</div>
							) : (
								[]
							)}
						</div>
					)}
					{isUserById(user, [record.userId]) && record.status < 2 ? (
						<input
							type="file"
							multiple
							style={{ marginTop: "5px" }}
							onChange={_addDocs}
						/>
					) : (
						[]
					)}
					{record.description && (
						<div className="rfi-response-comments">
							<span
								className={cx(
									"fas fa-comment",
									record.rfiComments.length ? "orange" : ""
								)}
								title={
									record.status < RFIStatus.Accepted
										? "click to view comments or add"
										: "comments locked, click to view"
								}
								onClick={_toggleComments}
							></span>
							<span className="comment-count">
								-{" "}
								{`${record.rfiComments.length} comment${
									record.rfiComments.length !== 1 ? "s" : ""
								}`}
							</span>
						</div>
					)}
					{showComments && (
						<RFICommentStream
							open={record.status <= RFIStatus.Answered}
							addComment={_addRFIComment}
							comments={record.rfiComments}
						/>
					)}
				</div>
				{renderRFIResponses(record.responses, isSubmitter)}
				{renderAddResponseSection(isActiveReviewer)}
				{showCancelModal && renderCancelModal()}
				<div style={{ clear: "both" }}></div>
				{renderClosingComment(record)}
				{renderClosingLine(record, isSubmitter || isPM)}
				<br />
				{record.reissuedFromId && (
					<RFIReissueHistory id={record.reissuedFromId} />
				)}
			</div>
			{removeReviewerSelectedId && renderRemoveReviewerModal()}
			{showCCedUsersDialog && renderCCedUsersDialog()}
			{showResponseModal && renderResponseModal()}
			{showSwapModal && (
				<RFISwapReviewerModal
					swapReviewer={swapReviewerObject.reviewer}
					swapDiscipline={swapReviewerObject.discipline}
					disciplines={getDisciplineOpts()}
					reviewers={globalStore.users.map(
						(x) => new ListItem(x.userId, x.fullName)
					)}
					onSave={_onSwap}
					onClose={() => setShowSwapModal(false)}
				/>
			)}
			{showTransferRecordModal && isAdmin && (
				<TransferRecordModal
					type={TransferRecordType.RFIs}
					onClose={() => setShowTransferRecordModal(false)}
					fromUserId={record.userId}
					recordId={record.id}
					onTransfer={(message: string) => {
						_getDetails(record.id);
						setMessage(message);
					}}
					users={projectTeamMembers}
				></TransferRecordModal>
			)}
		</div>
	) : (
		<div className="rfi-detail">
			<StandardGridLoader rowContentHeight={200} rowCount={4} rowPadding={10} />
		</div>
	);
};

const renderClosingComment = (record: RFIDetailVm) => {
	if (
		(record.status === RFIStatus.Accepted ||
			record.status === RFIStatus.Reissued ||
			record.status === RFIStatus.ReissueRequested) &&
		record.approvalComment &&
		record.approvalComment.length
	) {
		return (
			<div className="closing-comment">
				<div className="response-name-timestamp">
					<span>
						{record.approverName} - {formatDateTime(record.approvedTimestamp)}
					</span>
				</div>
				<div className="rfi-response-body">{record.approvalComment}</div>
			</div>
		);
	}
};

const isResponsePoster = (response: RFIResponse, user?: UserInfo) => {
	if (!user || response.userId !== user.userId) return false;

	return true;
};

const getDisciplineOpts = () => {
	const items: ListItem[] = [
		new ListItem("Architecture", "Architecture"),
		new ListItem("Civil", "Civil"),
		new ListItem("Electrical", "Electrical"),
		new ListItem(
			"General / Project Management",
			"General / Project Management"
		),
		new ListItem("Interiors", "Interiors"),
		new ListItem("Landscape", "Landscape"),
		new ListItem("Mechanical / HVAC", "Mechanical / HVAC"),
		new ListItem("Plumbing", "Plumbing"),
		new ListItem("Structural", "Structural"),
		new ListItem("Other", "Other"),
	];

	return items;
};
