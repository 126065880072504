import * as React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FilterableSelectContainer } from 'src/components/FilterableSelectContainer';
import { hasRole, KnownRoles } from '../auth/auth';
import { NoAuthElement } from '../auth/Authorization';
import FilterableSelect from '../components/FilterableSelect';
import { RecordNotes } from '../components/RecordNotes';
import UserCard from '../components/UserCard';
import { ClientInfoModal } from '../config/client/ClientInfoModal';
import { check_money_format, toMoney } from '../helpers/formatters';
import { sum } from '../helpers/formulas';
import { ListItem, ProjectDetailVm } from '../interfaces/interfaces';
import { DetailSectionLoader } from '../loaders/ProjectDetailLoader';
import { ApplicationState } from '../store';
import { actionCreators as detailActionCreators } from '../store/projectDetail';
import KeyDocBox from './KeyDocBox';
import ProjectLinks from './ProjectLinks';
import $ from 'jquery';
import {
  canRetrieveTemplatesSelector,
  safetyKpiTemplatesSelector,
} from 'src/store/safetyKPI/safetyKPI.selectors';
import { SafetyKPIActions } from 'src/store/safetyKPI/safetyKPI.reducer';

export const ProjectDetail = (props: { detail: ProjectDetailVm }) => {
  const userStore = useSelector((s: ApplicationState) => s.user);
  const detailStore = useSelector((s: ApplicationState) => s.detail);
  const kpiTemplates = useSelector(safetyKpiTemplatesSelector);
  const canRetrieveTemplates = useSelector(canRetrieveTemplatesSelector);
  const dispatch = useDispatch();
  const internalPmIdRef = React.useRef<HTMLDivElement>(null);
  const siteSuperIdRef = React.useRef<HTMLDivElement>(null);
  const externalPmIdRef = React.useRef<HTMLDivElement>(null);
  const generalSuperIdRef = React.useRef<HTMLDivElement>(null);
  const coordinatorIdRef = React.useRef<HTMLDivElement>(null);
  const accountantIdRef = React.useRef<HTMLDivElement>(null);

  const safetyKpiRef = React.useRef<HTMLDivElement>(null);

  const [bodyScroll, setBodyScroll] = React.useState(false);

  const NoVendorComponent = NoAuthElement([
    KnownRoles.Vendor,
    KnownRoles.OwnerLtd,
  ]);

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    const ele = e.currentTarget as HTMLInputElement;
    let val = ele.value;
    const field = ele.getAttribute('name') || '';
    if (field === 'budget') val = val.replace('$', '');
    dispatch(detailActionCreators.updateField(field, val));
  };

  const _handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const fieldName = e.currentTarget.getAttribute('name');
    const opt = e.currentTarget.querySelector(
      'option:checked'
    ) as HTMLOptionElement;
    if (opt && fieldName)
      dispatch(detailActionCreators.updateField(fieldName, opt.value));
  };

  const _filterableSelectChange = (name: string, val: string) =>
    dispatch(detailActionCreators.updateField(name, val));

  const { user } = userStore,
    { detail } = props;

  let vendDisable = false;
  if (user) {
    if (
      user.userId !== detail.internalPmId &&
      !hasRole(user, [KnownRoles.Admin, KnownRoles.CoreSuperUser])
    ) {
      vendDisable = true;
    }
  } else vendDisable = true;
  const statusOpts = detailStore.projectStatuses
    ? detailStore.projectStatuses.map((x) => (
        <option key={x.id} value={x.id}>
          {x.value}
        </option>
      ))
    : [];
  const typeOpts = detailStore.projectTypes
    ? detailStore.projectTypes.map((x) => (
        <option key={x.id} value={x.id}>
          {x.value}
        </option>
      ))
    : [];

  const start =
      detail.startDate && detail.startDate.length
        ? new Date(detail.startDate).toISOString().substring(0, 10)
        : '',
    end =
      detail.endDate && detail.endDate.length
        ? new Date(detail.endDate).toISOString().substring(0, 10)
        : '';
  const permitDate =
    detail.permitExpirationDate && detail.permitExpirationDate.length
      ? new Date(detail.permitExpirationDate).toISOString().substring(0, 10)
      : '';
  const currentCost =
    detail.materials && detail.materials.length
      ? detail.materials.map((x) => x.cost).reduce(sum)
      : 0;

  const userEmailSubject = `${detail.projectNumber} ${detail.name} - ${detail.address} ${detail.city}, ${detail.state}`;
  const userEmailBody = `To vue the project, please visit: ${window.location.href}`;

  const handleTop = React.useCallback(
    (ref: HTMLDivElement | null) => {
      $('body')[0].click();
      ref?.querySelector('input')?.blur();
      if (ref) {
        return ref.getBoundingClientRect().top + ref.offsetHeight + 'px';
      }
      return '';
    },

    [bodyScroll]
  );

  const handleWidth = React.useCallback(
    (ref: HTMLDivElement | null) => {
      if (ref) {
        return (
          (ref.firstChild?.firstChild as HTMLDivElement).offsetWidth + 'px'
        );
      }
      return '';
    },
    [
      bodyScroll,
      internalPmIdRef.current,
      siteSuperIdRef.current,
      externalPmIdRef.current,
      generalSuperIdRef.current,
      coordinatorIdRef.current,
      safetyKpiRef.current,
    ]
  );

  React.useEffect(() => {
    if (canRetrieveTemplates) {
      dispatch(SafetyKPIActions.getTemplates());
    }
  }, [canRetrieveTemplates, kpiTemplates]);

  if (detailStore.isLoading) return <DetailSectionLoader />;

  return (
    <div className="section project-detail">
      <h4>Details</h4>
      <div
        onScroll={() => setBodyScroll(!bodyScroll)}
        style={{ padding: '0px 6px', overflowY: 'auto', maxHeight: '70vh' }}
        className="custom-scrollbar"
      >
        <div className="col-sm-9 row flex-row detail-form">
          {/*
                        //@ts-ignore */}
          <div className="form-group">
            <div className="inline-label">Client</div>
            <ClientInfoModal
              id={detail.clientId}
              clientName={detail.clientName}
              user={userStore.user}
            />
          </div>
          <div className="form-group">
            <div className="inline-label">Division</div>
            <div className="input-holder">
              <FilterableSelect
                id="project-division"
                items={detailStore.divisions}
                defaultVal={detail.divisionId || ''}
                onChange={(id) =>
                  dispatch(detailActionCreators.updateField('divisionId', id))
                }
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Project Name</div>
            <div className="input-holder">
              <input
                key={detail.siteId}
                name="name"
                className="form-control"
                defaultValue={detail.name}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Site Id </div>
            <div className="input-holder">
              <input
                key={detail.siteId}
                name="siteId"
                className="form-control"
                defaultValue={detail.siteId}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          {/*
                        //@ts-ignore */}
          <NoVendorComponent class="form-group">
            <div className="inline-label">Budget </div>
            <div className="input-holder">
              <input
                key={detail.budget}
                name="budget"
                className="form-control"
                defaultValue={toMoney(detail.budget || 0).toString()}
                onKeyUp={check_money_format}
                disabled={vendDisable}
                onBlur={(e) => handleBlur(e)}
              />
            </div>
          </NoVendorComponent>
          {/*
                        //@ts-ignore */}
          <NoVendorComponent class="form-group">
            <div className="inline-label">Cost </div>
            <div className="input-holder">
              <input
                key={currentCost}
                disabled
                name="cost"
                className="form-control"
                defaultValue={toMoney(currentCost)}
              />
            </div>
          </NoVendorComponent>
          <div className="form-group">
            <div className="inline-label">Type </div>
            <div className="min-179 input-holder">
              <select
                onChange={_handleChange}
                name="projectTypeId"
                className="form-control"
                value={(detail.projectTypeId || 0).toString()}
                disabled={vendDisable}
              >
                <option value="0"></option>
                {typeOpts}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Wbs1</div>
            <div className="min-179 input-holder">
              <input
                key={detail.wbs1}
                name="wbs1"
                className="form-control"
                defaultValue={detail.wbs1}
                disabled={vendDisable}
                onBlur={(e) => handleBlur(e)}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Start Date </div>
            <div className="input-holder">
              <input
                key={detail.startDate}
                type="date"
                name="startDate"
                className="form-control"
                defaultValue={start}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">End Date </div>
            <div className="input-holder">
              <input
                key={detail.endDate}
                type="date"
                name="endDate"
                className="form-control"
                defaultValue={end}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Budgeted Days </div>
            <div className="min-179 input-holder">
              <input
                key={detail.budgetedDays}
                type="number"
                name="budgetedDays"
                className="form-control"
                defaultValue={detail.budgetedDays}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Actual Days </div>
            <div className="min-179 input-holder">
              <input
                key={detail.actualDays}
                type="number"
                name="actualDays"
                className="form-control"
                defaultValue={detail.actualDays}
                disabled={true}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Status</div>
            <div className="min-179 input-holder">
              <select
                onChange={_handleChange}
                name="projectStatusId"
                className="form-control"
                value={(detail.projectStatusId || 0).toString()}
                disabled={vendDisable}
              >
                <option value="0"></option>
                {statusOpts}
              </select>
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Punchlist Progress</div>
            <div className="min-179 input-holder">
              <input
                key={detail.punchlistProgress}
                name="punchlistProgress"
                className="form-control"
                defaultValue={detail.punchlistProgress}
                disabled={true}
              />
            </div>
          </div>
          <div className=" form-group">
            <div className="inline-label">Address </div>
            <div className="input-holder">
              <input
                key={detail.address}
                name="address"
                className="form-control"
                defaultValue={detail.address}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">City </div>
            <div className="input-holder">
              <input
                key={detail.city}
                name="city"
                className="form-control"
                defaultValue={detail.city}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">State </div>
            <div className="input-holder">
              <input
                key={detail.state}
                name="state"
                className="form-control"
                defaultValue={detail.state}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Zip </div>
            <div className="input-holder">
              <input
                key={detail.zip}
                name="zip"
                className="form-control"
                defaultValue={detail.zip}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
          <div className="form-group">
            <div className="inline-label">Internal PM </div>
            <FilterableSelectContainer
              top={handleTop(internalPmIdRef.current)}
              ulWidth={handleWidth(internalPmIdRef.current)}
              ref={internalPmIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="internalPmId"
                defaultValue={detail.internalPmId}
                contacts={detailStore.internalPMs}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={false}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Site Super </div>
            <FilterableSelectContainer
              top={handleTop(siteSuperIdRef.current)}
              ulWidth={handleWidth(siteSuperIdRef.current)}
              ref={siteSuperIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="siteSuperId"
                defaultValue={detail.siteSuperId}
                contacts={detailStore.internalPMs}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={false}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Client PM </div>
            <FilterableSelectContainer
              top={handleTop(externalPmIdRef.current)}
              ulWidth={handleWidth(externalPmIdRef.current)}
              ref={externalPmIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="externalPmId"
                defaultValue={
                  detail.externalPmId && detail.externalPmId.length
                    ? detail.externalPmId
                    : detail.externalPm
                }
                contacts={detailStore.clientPMs}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={true}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">General Super </div>
            <FilterableSelectContainer
              top={handleTop(generalSuperIdRef.current)}
              ulWidth={handleWidth(generalSuperIdRef.current)}
              ref={generalSuperIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="generalSuperId"
                defaultValue={
                  detail.generalSuperId && detail.generalSuperId.length
                    ? detail.generalSuperId
                    : detail.generalSuper
                }
                contacts={detailStore.internalPMs}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={false}
                allowEmpty={true}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Coordinator </div>
            <FilterableSelectContainer
              top={handleTop(coordinatorIdRef.current)}
              ulWidth={handleWidth(coordinatorIdRef.current)}
              ref={coordinatorIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="coordinatorId"
                defaultValue={
                  detail.coordinatorId && detail.coordinatorId.length
                    ? detail.coordinatorId
                    : detail.coordinatorName
                }
                contacts={detailStore.internalPMs}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={false}
                allowEmpty={true}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Project Accountant </div>
            <FilterableSelectContainer
              top={handleTop(accountantIdRef.current)}
              ulWidth={handleWidth(accountantIdRef.current)}
              ref={accountantIdRef}
              className="min-205 input-holder"
            >
              <UserCard
                changeHandler={_filterableSelectChange}
                name="accountantId"
                defaultValue={
                  detail.accountantId && detail.accountantId.length
                    ? detail.accountantId
                    : detail.accountantName
                }
                contacts={detailStore.accountingUsers}
                emailSubject={userEmailSubject}
                emailBody={userEmailBody}
                disabled={vendDisable}
                openField={false}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Safety KPI Template</div>
            <FilterableSelectContainer
              top={handleTop(safetyKpiRef.current)}
              ulWidth={handleWidth(safetyKpiRef.current)}
              ref={safetyKpiRef}
              className="min-205 input-holder"
            >
              <FilterableSelect
                id="safety-kpi"
                items={
                  kpiTemplates.length > 0
                    ? kpiTemplates.map((x) => {
                        return new ListItem(x.id, x.name);
                      })
                    : []
                }
                defaultVal={
                  kpiTemplates.find((x) => x.id == detail.safetyKPITemplateId)
                    ?.name
                }
                onChange={(id) =>
                  dispatch(
                    detailActionCreators.updateField('safetyKPITemplateId', id)
                  )
                }
                allowEmpty={true}
              />
            </FilterableSelectContainer>
          </div>
          <div className="form-group">
            <div className="inline-label">Permit Expiration Date </div>
            <div className="input-holder">
              <input
                key={detail.permitExpirationDate}
                type="date"
                name="permitExpirationDate"
                className="form-control"
                defaultValue={permitDate}
                onBlur={(e) => handleBlur(e)}
                disabled={vendDisable}
              />
            </div>
          </div>
        </div>
        <div
          className="col-sm-3 mobile-full-width"
          style={{ display: 'block' }}
        >
          <ProjectLinks />
          <div style={{ height: '21px' }}></div>
          <KeyDocBox projectId={detail.id} />
        </div>
        <div style={{ width: '75%', paddingRight: '15px' }}>
          <RecordNotes
            notes={detail.notes}
            label="Notes"
            update={(e) => dispatch(detailActionCreators.updateNotes(e))}
            canEdit={!vendDisable}
          />
        </div>
      </div>
    </div>
  );
};
