import React from 'react';
import {
  ProjectAccountingDetail,
  UpdateObject,
} from 'src/interfaces/interfaces';
import styled from 'styled-components';

const InputDiv = styled.div`
  width: calc(100% - 150px);
  max-width: 300px;
`;

const LabelDiv = styled.div`
  width: 200px;
  line-height: 2.5;
  font-weight: bold;
  margin-bottom: 5px;
`;

interface ProjectAccountingProps {
  accountingDetails: ProjectAccountingDetail | undefined;
  onCreate: () => void;
  onUpdate: (id: number, update: UpdateObject) => void;
  projectId: number | undefined;
  onAttachmentAdd: (id: number, file: File) => void;
  onAttachmentRemove: (id: number) => void;
  canEdit: boolean;
}

export const ProjectAccounting = ({
  accountingDetails,
  onCreate,
  onUpdate,
  projectId,
  onAttachmentAdd,
  onAttachmentRemove,
  canEdit,
}: ProjectAccountingProps) => {
  const onInputUpdate = (
    e:
      | React.FocusEvent<HTMLInputElement, Element>
      | React.ChangeEvent<HTMLSelectElement>
      | React.FocusEvent<HTMLTextAreaElement, Element>
  ) => {
    if (accountingDetails) {
      onUpdate(accountingDetails.id, {
        fieldName: e.target.name,
        value: e.target.value,
      });
    }
  };

  return (
    <div className="section">
      <h4>Accounting</h4>
      {projectId && projectId > 0 && (
        <>
          {!accountingDetails ? (
            <button
              onClick={onCreate}
              style={{ marginTop: '8px' }}
              className="btn btn-sm btn-blue"
            >
              Create Accounting Details
            </button>
          ) : (
            <div className="container-fluid">
              <h5>General Information</h5>
              <div className="form-group no-gutters row flex-column">
                <div className="col flex-row">
                  <LabelDiv>Department</LabelDiv>
                  <InputDiv>
                    <input
                      defaultValue={accountingDetails.department}
                      className="form-control"
                      disabled={true}
                    ></input>
                  </InputDiv>
                  {accountingDetails.department == 'OTHER - Other' && (
                    <span
                      style={{ alignSelf: 'center', marginLeft: '10px' }}
                      title="The selected department / division is not properly configured for importing to Vista"
                      className="fas fa-xl fa-exclamation-triangle"
                    ></span>
                  )}
                </div>
                <div className="col flex-row">
                  <LabelDiv>Customer #</LabelDiv>
                  <InputDiv>
                    <input
                      defaultValue={accountingDetails.customerNumber}
                      name="customerNumber"
                      className="form-control"
                      disabled={true}
                    ></input>
                  </InputDiv>
                </div>
              </div>
              <div className="row no-gutters">
                <div className="col-5">
                  <h5>Billing Information</h5>
                  <div className="form-group no-gutters row flex-column">
                    <div className="col flex-row">
                      <LabelDiv>Billing Type</LabelDiv>
                      <InputDiv>
                        <select
                          name="billingType"
                          className="form-control"
                          disabled={!canEdit}
                          value={accountingDetails.billingType}
                          onChange={(e) => onInputUpdate(e)}
                        >
                          <option value=""></option>
                          <option value="T&M">T & M</option>
                          <option value="Progress">Progress</option>
                        </select>
                      </InputDiv>
                    </div>
                    <div className="col flex-row">
                      <LabelDiv>Retainage Percentage</LabelDiv>
                      <InputDiv>
                        <input
                          type={'number'}
                          defaultValue={accountingDetails.retainagePercentage}
                          name="retainagePercentage"
                          className="form-control"
                          disabled={!canEdit}
                          step="0.01"
                          onBlur={(e) => onInputUpdate(e)}
                        ></input>
                      </InputDiv>
                    </div>
                    <div className="col flex-row">
                      <LabelDiv>Billing Format</LabelDiv>
                      <InputDiv>
                        <select
                          name="billingFormat"
                          className="form-control"
                          disabled={
                            !canEdit ||
                            accountingDetails.clientFormAttachment !== null
                          }
                          value={accountingDetails.billingFormat}
                          onChange={(e) => onInputUpdate(e)}
                        >
                          <option value=""></option>
                          <option value="Client Form">Client Form</option>
                          <option value="AIA">AIA</option>
                          <option value="Standard">Standard</option>
                        </select>
                      </InputDiv>
                    </div>
                    {accountingDetails.billingFormat == 'Client Form' &&
                      !accountingDetails.clientFormAttachment && (
                        <div className="col flex-row">
                          <LabelDiv>Attach Client Form Copy</LabelDiv>
                          <InputDiv>
                            <input
                              disabled={!canEdit}
                              type={'file'}
                              onChange={(e) => {
                                if (e.target.files?.length) {
                                  onAttachmentAdd(
                                    accountingDetails.id,
                                    e.target.files[0]
                                  );
                                }
                              }}
                            ></input>
                          </InputDiv>
                        </div>
                      )}
                    {accountingDetails.billingFormat == 'Client Form' &&
                      accountingDetails.clientFormAttachment && (
                        <div className="col flex-row">
                          <LabelDiv>Client Form Attachment</LabelDiv>
                          <InputDiv>
                            <a
                              style={{ lineHeight: '2.5' }}
                              href={
                                accountingDetails.clientFormAttachment.filePath
                              }
                              target="_blank"
                            >
                              View Client Form Attachment
                            </a>
                            {canEdit && (
                              <span
                                title="Remove Attachment"
                                className="btn btn-sm fas fa-times"
                                onClick={() =>
                                  onAttachmentRemove(
                                    accountingDetails.clientFormAttachment.id
                                  )
                                }
                              ></span>
                            )}
                          </InputDiv>
                        </div>
                      )}
                    <div className="col flex-row">
                      <LabelDiv>Billing schedule, frequency</LabelDiv>
                      <InputDiv>
                        <input
                          defaultValue={accountingDetails.billingSchedule}
                          name="billingSchedule"
                          className="form-control"
                          disabled={!canEdit}
                          onBlur={(e) => onInputUpdate(e)}
                        ></input>
                      </InputDiv>
                    </div>
                    <div className="col flex-row">
                      <LabelDiv>
                        Method of invoice submittal (email, mail, etc)
                      </LabelDiv>
                      <InputDiv>
                        <input
                          defaultValue={accountingDetails.invoiceMethod}
                          name="invoiceMethod"
                          className="form-control"
                          disabled={!canEdit}
                          onBlur={(e) => onInputUpdate(e)}
                        ></input>
                      </InputDiv>
                    </div>
                  </div>
                </div>
                <div className="col">
                  {accountingDetails.billingType == 'T&M' && (
                    <>
                      <h5>T & M Billing Information</h5>
                      <div className="form-group no-gutters row flex-column">
                        <div className="col flex-row">
                          <LabelDiv>What expenses can be billed?</LabelDiv>
                          <InputDiv>
                            <input
                              defaultValue={accountingDetails.billingExpenses}
                              name="billingExpenses"
                              className="form-control"
                              disabled={!canEdit}
                              onBlur={(e) => onInputUpdate(e)}
                            ></input>
                          </InputDiv>
                        </div>
                        <div className="col flex-row">
                          <LabelDiv>Mileage Rate</LabelDiv>
                          <InputDiv>
                            <input
                              type={'number'}
                              defaultValue={accountingDetails.mileageRate || 0}
                              name="mileageRate"
                              className="form-control"
                              disabled={!canEdit}
                              step="0.01"
                              onBlur={(e) => onInputUpdate(e)}
                            ></input>
                          </InputDiv>
                        </div>
                        <div className="col flex-row">
                          <LabelDiv>
                            Billing Notes / Special Requirements
                          </LabelDiv>
                          <InputDiv>
                            <textarea
                              defaultValue={accountingDetails.billingNotes}
                              name="billingNotes"
                              className="form-control"
                              disabled={!canEdit}
                              onBlur={(e) => onInputUpdate(e)}
                            ></textarea>
                          </InputDiv>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </>
      )}
    </div>
  );
};
