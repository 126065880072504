import * as React from 'react';

import { VendorVm, VendorRating, UserInfo } from '../interfaces/interfaces';
import { formatDate } from '../helpers/formatters';
import { NewRating } from './NewRating';

export const VendorRatings = (props: {
  vendor: VendorVm;
  user: UserInfo | undefined;
  onUpdate: (id: number, rating: number) => void;
}) => {
  const [showAddRating, setShowAddRating] = React.useState(false);
  const [vendorRatings, setVendorRatings] = React.useState(
    props.vendor.ratings
  );

  React.useEffect(() => {
    setVendorRatings(props.vendor.ratings);
  }, [props.vendor.ratings]);

  const { vendor, user, onUpdate } = props;

  const ratings =
    vendorRatings && vendorRatings.length ? (
      vendorRatings.map((r) => (
        <Rating
          userId={user?.userId ?? ''}
          rating={r}
          key={r.id}
          onUpdate={onUpdate}
        />
      ))
    ) : (
      <div>No ratings exist for this vendor. Be the first to add one!</div>
    );

  let remainingProjects = false;
  if (vendor.vendorProjects) {
    if (!vendorRatings) {
      remainingProjects = true;
    } else if (
      vendor.vendorProjects.filter(
        (x) => vendorRatings.findIndex((y) => y.projectId == x.projectId) === -1
      ).length
    ) {
      remainingProjects = true;
    }
  }

  const addBtn = remainingProjects ? (
    <button
      className="btn btn-sm btn-blue"
      style={{ position: 'absolute', right: '5px', fontSize: '10px' }}
      onClick={() => setShowAddRating(true)}
    >
      Add Rating
    </button>
  ) : (
    <button
      className="btn btn-sm btn-blue btn-disabled"
      style={{ position: 'absolute', right: '5px', fontSize: '10px' }}
      disabled={true}
      title="No unrated projects remaining for this project"
    >
      Add Rating
    </button>
  );

  return (
    <div className="vendor-ratings">
      <h5>Ratings {addBtn}</h5>
      {showAddRating && (
        <NewRating
          vendor={vendor}
          onSuccess={(r) => {
            setVendorRatings([...vendorRatings, r]);
            setShowAddRating(false);
          }}
        />
      )}
      {ratings}
    </div>
  );
};

const Rating = (props: {
  rating: VendorRating;
  userId: string;
  onUpdate: (id: number, rating: number) => void;
}) => {
  const [editing, setEditing] = React.useState(false);
  const [currentRating, setCurrentRating] = React.useState(props.rating.rating);
  const { rating, userId, onUpdate } = props;

  React.useEffect(() => {
    setCurrentRating(rating.rating);
  }, [rating]);

  return (
    <div key={rating.id} className="vendor-rating">
      <div className="rater-info">
        <b>{rating.userFullName}</b>
        <i> - {formatDate(rating.timestamp)}</i>
      </div>
      <div className="rating-title">
        <Stars
          editing={editing}
          rating={currentRating}
          onUpdate={(r) => {
            onUpdate(rating.id, r);
            setEditing(false);
            setCurrentRating(r);
          }}
        />
        &nbsp;
        <span>
          <b>{rating.projectName}</b>
        </span>
        {userId == rating.userId && !editing && (
          <span
            style={{ lineHeight: 1.5, marginLeft: '8px' }}
            className="fas fa-pencil"
            onClick={() => setEditing(!editing)}
          ></span>
        )}
      </div>
      <div className="rating-timestamp"></div>
      <div className="rating-notes">{rating.notes}</div>
    </div>
  );
};

const Stars = (props: {
  rating: number;
  editing: boolean;
  onUpdate: (rating: number) => void;
}) => {
  const stars = Array.from(Array(5).keys()).map((i) => {
    const className = i < props.rating ? 'fas fa-star' : 'far fa-star';

    return (
      <span
        style={{ cursor: props.editing ? 'pointer' : 'not-allowed' }}
        key={i}
        className={className}
        onClick={() => {
          props.editing && props.onUpdate(i + 1);
        }}
      ></span>
    );
  });

  return <div className="rating-stars">{stars}</div>;
};
