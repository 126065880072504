import React, { useMemo, useState } from 'react';
import FilterableSelect from 'src/components/FilterableSelect';
import { JoinedList } from 'src/components/JoinedList';
import { Loader } from 'src/components/Loader';
import { IssueStatus } from 'src/interfaces/enums';
import {
  IssueContributingFactor,
  IssueType,
  IssueVm,
  ListItem,
  ProjectTeamMember,
  UpdateObject,
} from 'src/interfaces/interfaces';
import { JoinedListDiv } from './IssueNCRModal';

interface IssueDetailProps {
  issue: IssueVm;
  onIssueUpdate: (update: UpdateObject) => void;
  onFilesAdd: (files: FileList) => void;
  onFileRemove: (id: number) => void;
  issueTypes: IssueType[];
  contributingFactors: IssueContributingFactor[];
  teamMembers: ProjectTeamMember[];
  onAddUsers: (selected: ListItem[]) => void;
  onRemoveUsers: (selected: ListItem[]) => void;
  showErrorMessage: (message: string) => void;
  isLoading: boolean;
}

export const IssueDetail = ({
  issue,
  onIssueUpdate,
  onFilesAdd,
  onFileRemove,
  issueTypes,
  contributingFactors,
  teamMembers,
  showErrorMessage,
  onAddUsers,
  onRemoveUsers,
  isLoading,
}: IssueDetailProps) => {
  const [showNCRModal, setShowNCRModal] = useState<boolean>(false);

  const availableCFs = useMemo(() => {
    return contributingFactors.filter((x) => x.issueTypeId == issue.typeId);
  }, [issue.typeId]);

  const availableTeamMembers = useMemo(() => {
    return teamMembers
      .filter((x) => !issue.ccedUsers.some((y) => y.userId == x.userId))
      .map((y) => new ListItem(y.userId, y.userFullName));
  }, [issue.ccedUsers]);

  const selectedTeamMembers = useMemo(() => {
    return issue.ccedUsers.map(
      (x) =>
        new ListItem(
          x.userId,
          teamMembers.find((y) => y.userId == x.userId)?.userFullName ?? ''
        )
    );
  }, [issue.ccedUsers]);

  const canComplete = useMemo(() => {
    if (
      (issue.contributingFactorId != null &&
        issue.rootCause != '' &&
        issue.timelineImpact > 0 &&
        issue.description !== '',
      issue.title !== '',
      issue.userId !== '')
    ) {
      return true;
    }
    return false;
  }, [issue]);

  return (
    <>
      <Loader loading={isLoading} />
      <div className="form-group">
        <div className="inline-label">Type*</div>
        <select
          disabled={!issue.canEdit}
          className="form-control"
          value={issue.type.id}
          onChange={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'typeId',
              value: value,
            });
          }}
        >
          {issueTypes.map((x) => {
            return (
              <option className="form-control" value={x.id} key={x.id}>
                {x.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group">
        <div className="inline-label">Contributing Factor*</div>
        <select
          disabled={!issue.canEdit}
          className="form-control"
          onChange={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'contributingFactorId',
              value: value,
            });
          }}
        >
          <option className="form-control" value={undefined}></option>
          {availableCFs.map((x) => {
            return (
              <option
                selected={issue.contributingFactorId == x.id}
                className="form-control"
                value={x.id}
                key={x.id}
              >
                {x.name}
              </option>
            );
          })}
        </select>
      </div>
      <div className="form-group">
        <div className="inline-label">Description*</div>
        <textarea
          disabled={!issue.canEdit}
          required
          defaultValue={issue.description}
          className="form-control"
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'description',
              value: value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <div className="inline-label">Assigned*</div>
        {issue.canEdit ? (
          <FilterableSelect
            required
            defaultVal={issue.userId}
            onChange={(x) => {
              onIssueUpdate({
                fieldName: 'userId',
                value: x,
              });
            }}
            id={'assignee'}
            items={teamMembers.map(
              (x) => new ListItem(x.userId, x.userFullName)
            )}
          />
        ) : (
          <input
            defaultValue={issue.assigneeName}
            className="form-control"
            disabled={true}
          />
        )}
      </div>
      <div className="form-group">
        <div className="inline-label">Responsible Party</div>
        <input
          disabled={!issue.canEdit}
          defaultValue={issue.responsibleParty}
          className="form-control"
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'responsibleParty',
              value: value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <div className="inline-label">Root Cause*</div>
        <input
          disabled={!issue.canEdit}
          defaultValue={issue.rootCause}
          className="form-control"
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'rootCause',
              value: value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <div className="inline-label">Timeline Impact (Days)*</div>
        <input
          disabled={!issue.canEdit}
          required
          type={'number'}
          className="form-control"
          value={issue.timelineImpact}
          onBlur={(e) => {
            const value = e.currentTarget.value;
            onIssueUpdate({
              fieldName: 'timelineImpact',
              value: value,
            });
          }}
        />
      </div>
      <div className="form-group">
        <div className="inline-label">Status</div>
        {issue.canEdit ? (
          <select
            className="form-control"
            onChange={(e) => {
              const value = e.currentTarget.value;
              if (value == '4' && !canComplete) {
                showErrorMessage(
                  'Please enter required fields before completeting this issue'
                );
              } else {
                onIssueUpdate({
                  fieldName: 'status',
                  value: value,
                });
              }
            }}
            value={issue.status}
          >
            {issue.status == IssueStatus.Draft && (
              <option className={'form-control'} value={IssueStatus.Draft}>
                Draft
              </option>
            )}
            {issue.status <= IssueStatus.Sent && (
              <option className={'form-control'} value={IssueStatus.Sent}>
                Sent
              </option>
            )}

            <option className={'form-control'} value={IssueStatus.InProgress}>
              In Progress
            </option>

            <option className={'form-control'} value={IssueStatus.InReview}>
              In Review
            </option>
            <option className={'form-control'} value={IssueStatus.Complete}>
              Complete
            </option>

            <option className={'form-control'} value={IssueStatus.Void}>
              Void (removes from project)
            </option>
          </select>
        ) : (
          <input
            defaultValue={issue.statusName}
            className="form-control"
            disabled={true}
          />
        )}
      </div>
      <div className="form-group">
        <div className="inline-label">Attachments</div>
        <div style={{ width: 'calc(100% - 150px)', paddingTop: '6px' }}>
          {issue.attachments?.map((x) => (
            <div style={{ marginBottom: '10px' }}>
              {x.fileName} &nbsp; &nbsp;
              <span
                className="fas fa-trash-alt"
                onClick={() => onFileRemove(x.id)}
              ></span>
            </div>
          ))}
          <input
            type="file"
            multiple
            onChange={(e) =>
              e.currentTarget.files ? onFilesAdd(e.currentTarget.files) : {}
            }
          />
        </div>
      </div>
      <JoinedListDiv className="form-group">
        <div className="inline-label">CCed Users</div>
        <JoinedList
          disabled={!issue.canEdit}
          title=""
          availableItems={availableTeamMembers}
          selectedItems={selectedTeamMembers}
          onAdd={onAddUsers}
          onRemove={onRemoveUsers}
        />
      </JoinedListDiv>
    </>
  );
};
