import React, { useEffect, useMemo, useState } from 'react';
import { JoinedList } from 'src/components/JoinedList';
import { Modal2 } from 'src/components/Modal';
import { IssueStatus } from 'src/interfaces/enums';
import { IssueVm, ListItem, NCRPostModel } from 'src/interfaces/interfaces';
import styled from 'styled-components';
import { ModalBodyDiv } from './IssueDetailModal';
import { NCRActions, ncrStatusSelector } from '../store/ncrs';
import { useDispatch, useSelector } from 'react-redux';

interface IssueNCRModalProps {
  issue: IssueVm;
  onModalClose: () => void;
  contributingFactors: ListItem[];
  onSendReport: (issueId: number, ncr: NCRPostModel) => void;
}

export const JoinedListDiv = styled.div({
  '& .form-group.joined-list': {
    marginLeft: '0px',
    marginRight: '0px',
    width: '100%',
    maxWidth: 'calc(100% - 150px);',
    paddingLeft: '0px',
  },
  '& .joined-list-container select.form-control': {
    height: '100px',
  },
  '& .button-container': {
    paddingTop: '20px',
  },
});

export const IssueNCRModal = ({
  issue,
  onModalClose,
  contributingFactors,
  onSendReport,
}: IssueNCRModalProps) => {
  const [hasCAP, setHasCAP] = useState(false);
  const [hasRCA, setHasRCA] = useState(false);
  const [localReport, setLocalReport] = useState<NCRPostModel>({
    supplier: issue.responsibleParty,
    nonConformanceReport: issue.type.name,
    description: issue.description,
    materialQuarantined: 'N/A',
    rootCause: issue.rootCause ?? '',
    contributingFactors: '',
    witnessInspection: false,
    transportationDamage: false,
    disposition: '',
    closed: issue.status == IssueStatus.Complete,
    projectId: issue.projectId,
    title: '',
    cAPFiles: null,
    rCAFiles: null,
  });

  const dispatch = useDispatch();
  const status = useSelector(ncrStatusSelector);

  useEffect(() => {
    return () => {
      dispatch(NCRActions.setStatus('idle'));
    };
  }, []);

  useEffect(() => {
    if (status === 'completed') {
      //alert('completed');
      onModalClose();
    }
  }, [status]);

  useEffect(() => {
    if (!hasCAP) {
      setLocalReport({ ...localReport, cAPFiles: null });
    }
  }, [hasCAP]);

  useEffect(() => {
    if (!hasRCA) {
      setLocalReport({ ...localReport, rCAFiles: null });
    }
  }, [hasRCA]);

  const selectedFactors = useMemo(() => {
    return localReport.contributingFactors
      .split(',')
      .filter((x) => x != '')
      .map(
        (x) =>
          new ListItem(
            x,
            contributingFactors.find((y) => y.value == x)?.value ?? ''
          )
      );
  }, [issue.ccedUsers]);

  const canSubmit = useMemo(() => {
    if (
      localReport.supplier == '' ||
      localReport.description == '' ||
      localReport.rootCause == '' ||
      localReport.disposition == ''
    ) {
      return false;
    }
    return true;
  }, [localReport]);

  return (
    <Modal2 dialogStyle={{ width: '800px' }}>
      <div className="modal-header">
        <h4>NCR Report</h4>
      </div>
      <ModalBodyDiv className="modal-body custom-scrollbar">
        <div className="form-group">
          <div className="inline-label">
            {issue.type.name == 'Material' ? 'Supplier*' : 'Responsible Party*'}
          </div>
          <input
            value={localReport.supplier}
            className="form-control"
            onChange={(e) => {
              setLocalReport({
                ...localReport,
                supplier: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Non-Conformance Type</div>
          <input
            disabled={true}
            value={localReport.nonConformanceReport}
            className="form-control"
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Description*</div>
          <input
            value={localReport.description}
            className="form-control"
            onChange={(e) => {
              setLocalReport({
                ...localReport,
                description: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Material Quarantined</div>
          <div
            style={{
              width: '100%',
              maxWidth: 'calc(100% - 150px)',
              alignSelf: 'center',
            }}
          >
            <input
              style={{ marginLeft: 0, marginRight: '8px' }}
              type="radio"
              name="material-quarantined"
              value={'Yes'}
              onChange={(e) => {
                setLocalReport({
                  ...localReport,
                  materialQuarantined: e.target.value,
                });
              }}
            />
            <label htmlFor="material-quarantined">Yes</label>
            <input
              style={{ marginRight: '8px' }}
              type="radio"
              name="material-quarantined"
              value={'No'}
              onChange={(e) => {
                setLocalReport({
                  ...localReport,
                  materialQuarantined: e.target.value,
                });
              }}
            />
            <label htmlFor="material-quarantined">No</label>
            <input
              style={{ marginRight: '8px' }}
              type="radio"
              name="material-quarantined"
              value={'N/A'}
              defaultChecked={true}
              onChange={(e) => {
                setLocalReport({
                  ...localReport,
                  materialQuarantined: e.target.value,
                });
              }}
            />
            <label htmlFor="material-quarantined">N/A</label>
          </div>
        </div>
        <div className="form-group">
          <div className="inline-label">Preliminary Root Cause*</div>
          <input
            value={localReport.rootCause}
            className="form-control"
            onChange={(e) => {
              setLocalReport({
                ...localReport,
                rootCause: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Root Cause Analysis</div>
          <label style={{ marginRight: '8px' }} className="switch">
            <input
              onClick={() => {
                setHasRCA(!hasRCA);
              }}
              checked={hasRCA}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
          {hasRCA && (
            <input
              onChange={(e) =>
                setLocalReport({
                  ...localReport,
                  rCAFiles: e.target.files,
                })
              }
              multiple
              accept=".pdf"
              type="file"
            ></input>
          )}
        </div>
        <JoinedListDiv className="form-group">
          <div className="inline-label">Contributing Factors</div>
          <JoinedList
            title=""
            availableItems={contributingFactors}
            selectedItems={selectedFactors}
            onChange={(items) => {
              setLocalReport({
                ...localReport,
                contributingFactors: items.map((x) => x.value).join(','),
              });
            }}
          />
        </JoinedListDiv>
        <div className="form-group">
          <div className="inline-label">Corrective Action Plan</div>
          <label style={{ marginRight: '8px' }} className="switch">
            <input
              onClick={() => {
                setHasCAP(!hasCAP);
              }}
              checked={hasCAP}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
          {hasCAP && (
            <input
              onChange={(e) =>
                setLocalReport({
                  ...localReport,
                  cAPFiles: e.target.files,
                })
              }
              multiple
              accept=".pdf"
              type="file"
            ></input>
          )}
        </div>
        <div className="form-group">
          <div className="inline-label">Witness Inspection Prior</div>
          <label className="switch">
            <input
              onClick={() => {
                setLocalReport({
                  ...localReport,
                  witnessInspection: !localReport.witnessInspection,
                });
              }}
              checked={localReport.witnessInspection}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="form-group">
          <div className="inline-label">Transportation Damage</div>
          <label className="switch">
            <input
              onClick={() => {
                setLocalReport({
                  ...localReport,
                  transportationDamage: !localReport.transportationDamage,
                });
              }}
              checked={localReport.transportationDamage}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
        <div className="form-group">
          <div className="inline-label">Disposition*</div>
          <input
            value={localReport.disposition}
            className="form-control"
            onChange={(e) => {
              setLocalReport({
                ...localReport,
                disposition: e.target.value,
              });
            }}
          />
        </div>
        <div className="form-group">
          <div className="inline-label">Closed</div>
          <label className="switch">
            <input
              onClick={() => {
                setLocalReport({
                  ...localReport,
                  closed: !localReport.closed,
                });
              }}
              checked={localReport.closed}
              type="checkbox"
            />
            <span className="slider round"></span>
          </label>
        </div>
      </ModalBodyDiv>
      <div className="modal-footer">
        <button
          disabled={!canSubmit}
          className="btn btn-sm btn-blue"
          onClick={() => {
            onSendReport(issue.id, localReport);
          }}
        >
          Create
        </button>
        <span
          className="btn btn-sm btn-outline-secondary"
          onClick={onModalClose}
        >
          Close
        </span>
      </div>
    </Modal2>
  );
};
