import * as React from 'react';
import { ClientVm } from 'src/interfaces/interfaces';
import { MessageBox } from '../../components/MessageBox';

interface Props {
  clientId: string | number;
}

interface State {
  config: ClientGeneralConfig;
  message?: string;
}

export interface ClientGeneralConfig {
  id: number;
  clientId: number;
  allowUserEnteredRFIDate: boolean;
  rfiDueDateDaysOffset: number;
  rfiDueRollverHour: number;
  allowUserEnteredSubmittalDate: boolean;
  submittalDueDateDaysOffset: number;
  submittalDueRolloverHour: number;
}

export const GeneralConfigForm = (props: Props) => {
  const [config, setConfig] = React.useState({
    id: 0,
    clientId: 0,
    allowUserEnteredRFIDate: false,
    allowUserEnteredSubmittalDate: false,
    rfiDueDateDaysOffset: 0,
    submittalDueDateDaysOffset: 0,
  } as ClientGeneralConfig);
  const [message, setMessage] = React.useState<string | undefined>();
  const [client, setClient] = React.useState<ClientVm>();

  React.useEffect(() => {
    _getGeneralConfig(props.clientId);
    _getClientDetail(props.clientId);
  }, [props.clientId]);

  const _getGeneralConfig = (clientId: number | string) => {
    if (clientId !== undefined && clientId != 0) {
      fetch(`api/ClientGeneralConfig/GetConfig?clientId=${clientId}`)
        // @ts-ignore
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          setConfig(data);
        });
    }
  };

  const _getClientDetail = (clientId: number | string) => {
    if (clientId !== undefined && clientId != 0) {
      fetch(`api/Client/Detail?id=${clientId}`)
        .then((res) => Promise.all([res.ok, res.json()]))
        .then(([resOk, data]) => {
          setClient(data);
        });
    }
  };

  const _updateInfo = React.useCallback(() => {
    const model = JSON.parse(JSON.stringify(client)) as ClientVm;

    fetch(`api/Client/Edit?id=${model.id}`, {
      method: 'PUT',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model),
    })
      .then((res) => Promise.all([res.json()]))
      .then(([data]) => {
        _getClientDetail(props.clientId);
      });
  }, [client, props.clientId]);

  const _save = () => {
    const form = document.querySelector(
        'div#generalClientConfig'
      ) as HTMLDivElement,
      allowRfi = (
        form.querySelector('#allowUserEnteredRFIDate') as HTMLInputElement
      ).checked,
      allowSubbmital = (
        form.querySelector('#allowUserEnteredSubmittalDate') as HTMLInputElement
      ).checked,
      rfiOffset = (
        form.querySelector('#rfiDueDateDaysOffset') as HTMLInputElement
      ).value,
      submittalOffset = (
        form.querySelector('#submittalDueDateDaysOffset') as HTMLInputElement
      ).value;

    const postBody = {
      id: config.id,
      clientId: config.clientId,
      allowUserEnteredRFIDate: allowRfi,
      allowUserEnteredSubmittalDate: allowSubbmital,
      rfiDueDateDaysOffset: rfiOffset,
      submittalDueDateDaysOffset: submittalOffset,
    };

    if (
      client &&
      !(
        client.accountingClientID == null &&
        client.accountingClientNumber == null
      )
    ) {
      _updateInfo();
    }

    fetch(`api/ClientGeneralConfig/Update`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(postBody),
    })
      // @ts-ignore
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          setConfig(data);
          setMessage('Saved');
        } else {
          setMessage(data.message);
        }
      });
  };

  const _toggleAllowRfi = () => {
    setConfig({
      ...config,
      allowUserEnteredRFIDate: !config.allowUserEnteredRFIDate,
    });
  };

  const _toggleAllowSubmittal = () => {
    setConfig({
      ...config,
      allowUserEnteredSubmittalDate: !config.allowUserEnteredSubmittalDate,
    });
  };

  const _clearMessage = () => setMessage(undefined);

  return (
    <div className="config-form config-flex max-350">
      <MessageBox message={message} clearMessage={_clearMessage} />
      <div
        className="config-new-item-form"
        style={{ minWidth: '50%', width: '100%' }}
      >
        <div id="generalClientConfig">
          <div className="form-header">
            <h5 className="modal-title">General Config Options</h5>
          </div>
          <div style={{ display: 'flex' }} className="form-body">
            <div className="col">
              <div className="form-group">
                <label>Allow Users to Enter RFI Due Dates: </label> &nbsp;
                <input
                  key={config.id}
                  id="allowUserEnteredRFIDate"
                  name="allowUserEnteredRFIDate"
                  type="checkbox"
                  checked={config.allowUserEnteredRFIDate}
                  onChange={_toggleAllowRfi}
                />
              </div>
              <div className="form-group">
                <label>
                  RFI Due Date Offset <small>(business days)</small>
                </label>
                <input
                  key={config.id}
                  id="rfiDueDateDaysOffset"
                  name="rfiDueDateDaysOffset"
                  className="form-control"
                  type="number"
                  defaultValue={config.rfiDueDateDaysOffset}
                  disabled={config.allowUserEnteredRFIDate}
                />
              </div>
              <div className="form-group">
                <label>Allow Users to Enter Submittal Due Dates: </label> &nbsp;
                <input
                  key={config.id}
                  id="allowUserEnteredSubmittalDate"
                  name="allowUserEnteredSubmittalDate"
                  type="checkbox"
                  checked={config.allowUserEnteredSubmittalDate}
                  onChange={_toggleAllowSubmittal}
                />
              </div>
              <div className="form-group">
                <label>
                  Submittal Due Date Offset <small>(business days)</small>
                </label>
                <input
                  key={config.id}
                  id="submittalDueDateDaysOffset"
                  name="submittalDueDateDaysOffset"
                  className="form-control"
                  type="number"
                  defaultValue={config.submittalDueDateDaysOffset}
                  disabled={config.allowUserEnteredSubmittalDate}
                />
              </div>
            </div>
            <div className="col">
              {props.clientId !== undefined && props.clientId != 0 && (
                <>
                  <div className="form-group">
                    <label>Accounting Client ID </label> &nbsp;
                    <input
                      key={config.id}
                      className="form-control"
                      disabled={
                        client?.accountingClientID != null &&
                        client?.accountingClientID != ''
                      }
                      onChange={(e) => {
                        if (client) {
                          setClient({
                            ...client,
                            accountingClientID: e.currentTarget.value,
                          });
                        }
                      }}
                      value={client?.accountingClientID ?? ''}
                    />
                  </div>
                  <div className="form-group">
                    <label>Accounting Client Number </label> &nbsp;
                    <input
                      key={config.id}
                      onChange={(e) => {
                        if (client) {
                          setClient({
                            ...client,
                            accountingClientNumber: e.currentTarget.value,
                          });
                        }
                      }}
                      className="form-control"
                      value={client?.accountingClientNumber ?? ''}
                    />
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        <div className="form-footer">
          <button className="btn btn-sm btn-blue" onClick={_save}>
            Save
          </button>
        </div>
      </div>
    </div>
  );
};
