import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListItem } from 'src/interfaces/interfaces';
import { ApplicationState } from 'src/store';
import {
  contributingFactorsSelector,
  isIssuesLoadingSelector,
  isIssuesSavingSelector,
  IssuesActions,
  issueTypesSelector,
  selectedIssueSelector,
} from 'src/store/issues';
import { IssueDetail } from './IssueDetail';
import { isNCRSavingSelector } from '../store/ncrs';

export const ConnectedIssueDetail = () => {
  const selectedIssue = useSelector(selectedIssueSelector);
  const issueTypes = useSelector(issueTypesSelector);
  const contributingFactors = useSelector(contributingFactorsSelector);
  const projectStore = useSelector((s: ApplicationState) => s.detail);
  const issuesLoading = useSelector(isIssuesLoadingSelector);
  const issueSaving = useSelector(isIssuesSavingSelector);
  const ncrSaving = useSelector(isNCRSavingSelector);

  const dispatch = useDispatch();

  const addUsers = useCallback(
    (selected: ListItem[]) => {
      const userIds = selected.map((x) => x.id).join(';');
      dispatch(
        IssuesActions.addCCUsers({
          id: selectedIssue?.id ?? 0,
          userIds,
          emails: '',
        })
      );
    },
    [selectedIssue]
  );

  const removeUsers = useCallback(
    (selected: ListItem[]) => {
      const userIds = selected.map((x) => x.id).join(';');
      dispatch(
        IssuesActions.removeCCUsers({ id: selectedIssue?.id ?? 0, userIds })
      );
    },
    [selectedIssue]
  );

  return (
    <>
      {selectedIssue ? (
        <IssueDetail
          issue={selectedIssue}
          onIssueUpdate={(updateObject) =>
            dispatch(
              IssuesActions.updateIssue({
                id: selectedIssue.id,
                info: updateObject,
              })
            )
          }
          onFilesAdd={(files) =>
            dispatch(
              IssuesActions.addFiles({ id: selectedIssue.id, files: files })
            )
          }
          onFileRemove={(id) =>
            dispatch(
              IssuesActions.removeFile({
                issueId: selectedIssue.id,
                fileId: id,
              })
            )
          }
          issueTypes={issueTypes}
          contributingFactors={contributingFactors}
          teamMembers={projectStore.detail.teamMembers ?? []}
          showErrorMessage={(message) =>
            dispatch({
              type: 'UPDATE_MESSAGE_ACTION',
              message: 'Error: ' + message,
            })
          }
          onAddUsers={addUsers}
          onRemoveUsers={removeUsers}
          isLoading={issueSaving || ncrSaving || issuesLoading}
        />
      ) : (
        <></>
      )}
    </>
  );
};
