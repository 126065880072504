import * as React from 'react';
import { MessageBox } from '../components/MessageBox';
import { Loader } from '../components/Loader';
import FilterableSelect from '../components/FilterableSelect';
import { ListItem, VendorProject } from '../interfaces/interfaces';
import { VendorInfoModal } from '../vendors/VendorInfoModal';
import { FilterableSelectContainer } from '../components/FilterableSelectContainer';
import { ChecklistDetailModal } from '../checklists/ChecklistDetailModal';
import {
  ChecklistStatus,
  ChecklistDetail,
  ProjectTeamMember,
  ChecklistType,
} from "../interfaces/interfaces"; // Import ChecklistType

interface Props {
  projectId: number,
}

interface State {
  loading: boolean;
  showAddVendor: boolean;
  showFilters: boolean;
  editingId?: number;
  allVendors: ListItem[];
  projectVendors: VendorProject[];
  filters: ListItem[],
  sortProp?: string
  sortAsc: boolean;
  message?: string;
  showChecklistModal: boolean; // New state for modal visibility
  selectedChecklistId?: number; // New state for selected checklist ID
}

interface ChecklistGridApi {
  toggleDetailId: (id: number | null) => void;
  refreshList: () => void;
}

export const ChecklistsGridContext = React.createContext(
  {} as ChecklistGridApi
);

export default class ProjectVendorGrid extends React.Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      loading: true,
      showAddVendor: false,
      showFilters: false,
      projectVendors: [],
      allVendors: [],
      filters: [],
      sortAsc: true,
      showChecklistModal: false,
    }
  }

  componentDidMount() {
    this._getAllVendors();
    this._getProjectVendors();
  }

  _getAllVendors = () => {
    fetch(`api/Vendor/GetAll?activeOnly=${true}`)
      // @ts-ignore
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ allVendors: data, loading: false })
        else this.setState({ message: data.message, loading: false })
      })
  }

  _getProjectVendors = () => {
    this.setState({ loading: true });
    fetch(`api/ProjectVendor/Get?projectId=${this.props.projectId}`)
      .then(res => res.json())
      .then(data => this.setState({ projectVendors: data, loading: false}))
  }

  _addVendor = () => {
    const form = document.querySelector('.add-vendor-line') as HTMLDivElement,
      fields = form.querySelectorAll('input'),
      { projectId } = this.props;

    const body = { projectId: projectId };
    [].forEach.call(fields, (f: HTMLInputElement) => {
      const split = f.name.split('_'), field = split[0] // we do this for the filterable selects which append '_list' to the hidden input which holds the value
      body[field] = f.type === 'checkbox' ? f.checked : f.value
    })

    fetch(`api/ProjectVendor/Add`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(body)
    })
      // @ts-ignore
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          this.setState({ showAddVendor: false, loading: false }, () => {
            this._getProjectVendors(); // Refresh the project vendors list
          });
        } else {
          this.setState({ message: data.message, loading: false });
        }
      })
  }

  _updateField = (e: React.FocusEvent<HTMLInputElement>) => {
    const field = e.currentTarget.name;
    const value = e.currentTarget.type === 'checkbox' ? e.currentTarget.checked : e.currentTarget.value;
    const { editingId } = this.state;

    fetch(`api/ProjectVendor/Edit?projectId=${this.props.projectId}&vendorId=${editingId}`,
      {
        method: 'PUT',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          fieldName: field,
          value: value
        })
      })
      // @ts-ignore
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const vendors = JSON.parse(JSON.stringify(this.state.projectVendors))
            .map((x: VendorProject) => {
              if (x.vendorId === editingId) x = data;
              return x;
            })
          this.setState({ projectVendors: vendors, loading: false })
        }
        else this.setState({ message: data.message, loading: false })
      })
  }

  _removeVendor = (id: number) => {
    fetch(`api/ProjectVendor/Delete?projectId=${this.props.projectId}&vendorId=${id}`, { method: 'DELETE' })
      // @ts-ignore
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) this.setState({ projectVendors: this.state.projectVendors.filter(x => x.vendorId !== id), loading: false })
        else this.setState({ message: data.message, loading: false })
      })
  }

  _startChecklist = (projectId: number, listId: number, vendorId: number) => {
    this.setState({ loading: true });
    fetch(`api/SPR/Create?projectId=${projectId}&vendorId=${vendorId}&listId=${144}`, {
      method: 'POST',
    })
      .then(res => {
        if (!res.ok) {
          this.setState({ loading: false });
          throw new Error('Network response was not ok');
        }
        return res.json();
      })
      .then(data => {
        if (data.message === "Checklist saved"){
          this._getProjectVendors(); // Refresh the project vendors list
          this.setState({ showChecklistModal: true })
          this._showChecklistModal(data.checklistId);
        } else {
          this.setState({ message: data.message, loading: false });
        }
      })
      .catch(error => {
        this.setState({ message: error.message, loading: false });
      });
  }

  _applyFilters = (vendors: VendorProject[]) => {
    const { filters, sortAsc, sortProp } = this.state;

    filters.forEach(x => {
      const fields = x.id.toString().split('|');
      if (fields.length > 1) {
        vendors = vendors.filterByMultiStringProp([...fields], x.value)
      }
      else vendors = vendors.filterByStringProp(fields[0], x.value)
    })

    vendors = vendors.filter(x => x !== undefined);

    if (sortProp) {
      vendors = vendors.sort((a, b) => {
        const aVal = a[sortProp], bVal = b[sortProp]
        if (bVal === undefined) return -1;
        if (aVal === bVal) return 0;
        if (sortAsc) return aVal > bVal ? 1 : -1;
        else return aVal > bVal ? -1 : 1;
      })
    }

    return vendors;
  }

  _filter = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.currentTarget.value;
    const field = e.currentTarget.getAttribute('name') || ''
    let filterExists = false;
    let filters = JSON.parse(JSON.stringify(this.state.filters))
      .map(x => {
        if (x.id === field) {
          filterExists = true;
          x.value = val;
        }
        return x;
      })

    if (!filterExists) filters.push({ id: field, value: val } as ListItem)
    filters = filters.filter(x => x !== undefined)
    this.setState({ filters: filters })
  }

  _setEditVendor = (id?: number) => this.setState({ editingId: id })

  _toggleShowAddVendor = () => this.setState({ showAddVendor: !this.state.showAddVendor });

  _toggleFilters = () => this.setState({ showFilters: !this.state.showFilters })

  _sort = (e: React.MouseEvent<HTMLLabelElement>) => {
    const prop = e.currentTarget.getAttribute('data-for') || undefined;
    this.setState({ sortProp: prop, sortAsc: !this.state.sortAsc })
  }

  _clearFilters = () => this.setState({ filters: [] })

  _clearMessage = () => this.setState({ message: undefined });

  _showChecklistModal = (checklistId: number) => {
    this.setState({ showChecklistModal: true, selectedChecklistId: checklistId });
  }

  _closeChecklistModal = () => {
    this.setState({ showChecklistModal: false, selectedChecklistId: undefined });
  }

  api = {
    toggleDetailId: (id) => this.setState({ selectedChecklistId: id ?? undefined }),
    refreshList: () => this._getProjectVendors(),
  } as ChecklistGridApi;

  render() {
    const { loading, showAddVendor, showFilters, message, showChecklistModal, selectedChecklistId } = this.state;

    return (

      <div className='grid project-team-grid'>
        <ChecklistsGridContext.Provider value={this.api}>
          {this.renderHeader()}
          {showFilters && this.renderFilterLine()}
          <div className='grid-body custom-scrollbar' style={{ minHeight: '60vh' }}>
            {showAddVendor && this.renderAddLine()}
            {this.renderLines()}
          </div>
          <MessageBox message={message} clearMessage={this._clearMessage} />
          <Loader loading={loading} />
          {showChecklistModal && selectedChecklistId !== undefined && (
            <ChecklistDetailModal id={selectedChecklistId} type={ChecklistType.Subcontractor} checklistsGridContext={ChecklistsGridContext} />
          )}
        </ChecklistsGridContext.Provider>
      </div>

    )
  }

  renderHeader() {
    const { sortProp, sortAsc } = this.state;
    const sortIconClass = sortAsc ? 'sort-icon fas fa-arrow-up' : 'sort-icon fas fa-arrow-down';
    const sortIcon = sortProp ? <span className={sortIconClass}></span> : [];

    return (
      <div className='grid-header team-grid-header my-col-20'>
        <div className='my-col-5'>
          <label data-for='vendorName' className='sortable' onClick={this._sort}>
            Vendor {sortProp === 'vendorName' && sortIcon}
          </label>
        </div>
        <div className='my-col-2'>
          <label data-for='subcontractorProjectReviewChecklistHeaderId'>Project Review</label>
        </div>
        <div className='my-col-11'>
          <label data-for='notes'>Notes</label>
        </div>
        <div className='right-button'>
          <button className='fas fa-filter btn btn-sm btn-outline-secondary' onClick={this._toggleFilters}></button>
          <button className='fas fa-plus btn btn-sm btn-blue' onClick={this._toggleShowAddVendor}></button>
        </div>
      </div>
    );
  }

  renderLines() {
    const { projectVendors, editingId } = this.state;

    return this._applyFilters(projectVendors).map((x, i) => {
      const editing = x.vendorId === editingId;
      return (
        <div key={x.vendorId} className='team-grid-line my-col-20' style={{ display: 'flex' }}>
          <div className='my-col-5'>
            <VendorInfoModal id={x.vendorId} vendorName={x.vendorName} />
          </div>
          <div className='my-col-2'>
            {x.reviewChecklistId ? (
              <button className='btn btn-x-sm btn-outline-secondary' onClick={() => x.reviewChecklistId !== null && this._showChecklistModal(x.reviewChecklistId)}>View Checklist</button>
            ) : (
              <button className='btn btn-x-sm btn-blue' onClick={() => this._startChecklist(this.props.projectId, 64, x.vendorId)}>Start Checklist</button>
            )}
          </div>
          <div className='my-col-11'>
            <input className='form-control' type='text' name='notes' defaultValue={x.notes} disabled={!editing} onBlur={this._updateField} />
          </div>
          <div className='right-button' style={{ marginLeft: 'auto', top: '8px' }}>
            {editing ? (
              <button className='fas fa-check btn btn-sm btn-blue' onClick={() => this._setEditVendor(undefined)}></button>
            ) : (
              <button className='fas fa-pencil-alt btn btn-sm btn-outline-secondary' onClick={() => this._setEditVendor(x.vendorId)} title='Edit this task'></button>
            )}
            <button className='fas fa-times btn btn-sm btn-outline-secondary' onClick={() => this._removeVendor(x.vendorId)}></button>
          </div>
        </div>
      );
    });
  }

  renderAddLine() {
    const { projectVendors, allVendors } = this.state;

    return (
      <div key='add' className='team-grid-line add-vendor-line my-col-20' style={{ display: 'flex' }}>
        <div className='my-col-5'>
          <FilterableSelect id='vendorId' items={allVendors.filter(x => projectVendors.findIndex(y => y.vendorId === x.id) === -1)} />
        </div>
        <div className='my-col-2'>
        </div>
        <div className='my-col-11'>
          <input className='form-control' name='notes' id='notes' />
        </div>
        <div className='right-button' style={{ marginLeft: 'auto', top: '8px' }}>
          <button className='fas fa-check btn btn-sm btn-blue' onClick={this._addVendor}></button>
          <button className='fas fa-ban btn btn-sm btn-outline-secondary' onClick={this._toggleShowAddVendor} title='Cancel'></button>
        </div>
      </div>
    );
  }

  renderFilterLine() {
    return (
      <div className='team-grid-line my-col-20'>
        <div className='my-col-5'><input name='vendorName' onChange={this._filter} /></div>
        <div className='my-col-2'></div>
        <div className='my-col-11'><input name='notes' onChange={this._filter} /></div>
        <div className='right-button' style={{ top: '8px' }}>
          <button className='btn btn-sm btn-outline-secondary' onClick={this._clearFilters}>Clear</button>
        </div>
      </div>
    )
  }
}
