import * as React from 'react';
import { connect } from 'react-redux'
import * as ConfigStore from '../../store/config';
import { ApplicationState } from '../../store';
import NewMilestoneForm from './MilestoneForm';
import BrandForm from './BrandForm'
import ProjectTypeForm from './ProjectTypeForm';
import { ProjectStatusForm } from './ProjectStatusForm';
import { SketchPicker } from 'react-color'

import KeyDocForm from './KeyDocConfig';
import { ListItem } from '../../interfaces/interfaces';
import DefaultFolderForm from '../global/DefaultFolders';
import {GeneralConfigForm} from './GeneralConfigForm';
import { ClientChecklistConfig } from './ClientChecklistConfig';

interface State {
    clientId: number
    clients: ListItem[]
    loading: boolean
    color?: string
    showPicker: boolean;
}

type Props =
    any
    & ConfigStore.Store
    & typeof ConfigStore.actionCreators

class ClientConfig extends React.Component<Props, State> {
    constructor(props) {
        super(props)
        this.state = {
            clientId: 0,
            clients: [],
            loading: false,
            showPicker: false
        }
    }

    componentDidMount() {
        this._getClients();
    }

    _getClients = () => {
        fetch(`api/Client/Get`)
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                this.setState({ clients: data })
            });
    }

    _changeClient = (e: React.ChangeEvent<HTMLSelectElement>) => {
        const opt = e.currentTarget.querySelector('option:checked') as HTMLOptionElement;
        if (opt) {
            this.setState({ clientId: parseInt(opt.value) })
        }

        this._getClientColor(parseInt(opt.value))
    }

    _getClientColor = (id: number) => {
        fetch(`api/Client/Color?id=${id}`)
            .then(res => res.text())
            .then(color => this.setState({ color: color }))
    }

    _updateColor = (color) => {
        this.setState({ color: color.hex })
        fetch(`api/Client/Color?id=${this.state.clientId}&hex=${encodeURIComponent(color.hex)}`, { method: 'PUT' })
            .then(res => Promise.all([res.ok, res.json()]))
            .then(([resOk, data]) => {
                if (resOk) this.setState({ color: color.hex })
                else alert('Error: ' + data.message)
            })
    }

    _toggleColorPicker = () => this.setState({ showPicker: !this.state.showPicker })

    render() {
        const { clientId, clients, color, showPicker } = this.state
        const clientOpts = clients ?
            clients.map((x: any) => <option key={x.id} value={x.id}>{x.value}</option>) : []

        return (
            <div className='config-tab-content'>
                <h5>Select Client</h5>
                <div style={{ display: 'flex' }}>
                    <select style={{ maxWidth: '250px' }} className='form-control' onChange={this._changeClient}>
                        <option value='0'></option>
                        {clientOpts}
                    </select>
                    {clientId !== 0 && <div className='client-color-section'>
                        <label>Color<span className='fas fa-question' title='Used for styles such as markers on the project map'></span>: </label>
                        <div className='color-block' style={{ backgroundColor: color || '#005a78' }} onClick={this._toggleColorPicker}></div>
                        {showPicker && <SketchPicker color={color || '005a78'} onChangeComplete={this._updateColor} />}
                    </div>
                    }
                </div>
                <br />
                <div className='config-tab-tiles'>
                    <div className='config-tab-tile'>
                        <GeneralConfigForm clientId={clientId} />
                    </div>
                    <div className='config-tab-tile'>
                        <ClientChecklistConfig id={clientId} />
                    </div>
                    <div className='config-tab-tile'>
                        <BrandForm clientId={clientId} addBrand={this.props.addBrand} />
                    </div>
                    {/*<div className='config-tab-tile'>
                        <VendorForm clientId={clientId} addVendor={this.props.addVendor} />
                    </div>*/}
                    <div className='config-tab-tile'>
                        <NewMilestoneForm clientId={clientId} addMilestone={this.props.addMilestone} />
                    </div>
                    <div className='config-tab-tile'>
                        <ProjectTypeForm clientId={clientId} addProjectType={this.props.addProjectType} />
                    </div>
                    <div className='config-tab-tile'>
                        <ProjectStatusForm clientId={clientId} addStatus={this.props.addStatus}
                            editStatus={this.props.editStatus}
                            reorderStatus={this.props.reorderStatus}
                        />
                    </div>
                    <div className='config-tab-tile'>
                        <KeyDocForm clientId={clientId} />
                    </div>
                    <div className='config-tab-tile'>
                        <DefaultFolderForm controller='ProjectCloseout' getAction='DefaultFolders'
                            postAction='AddFolder' putAction='EditFolder'
                            deleteAction='DeleteFolder' rootPath='Closeout'
                            params={{ clientId: clientId }}
                            headerLabel='Client Project Closeout Folder Structure'
                        />
                    </div>                    
                </div>
            </div>
        )
    }
}
export default connect<ConfigStore.Store, typeof ConfigStore.actionCreators, any>(
    //@ts-ignore
    (state: ApplicationState) => state.config,
    ConfigStore.actionCreators
)(ClientConfig)// as typeof ClientConfig