import { Reducer, Action } from "redux";
import { toMoney } from "../helpers/formatters";
import { RFQ } from '../interfaces/interfaces';
import { ProcurementQuoteStatus } from "../interfaces/enums";
import {
  ListItem,
  ProcurementItem,
  ProcurementQuote,
  QuoteSummary,
} from "../interfaces/interfaces";
import { AppThunkAction } from "./index";
import { request } from "http";

export interface Store {
  projectId: number;
  showAddModal: boolean;
  procurementItems: ProcurementItem[];
  quotes: ProcurementQuote[];
  selectedItemId: number | null;
  selectedQuoteId: number | null;
  loading: boolean;
  message?: string;
}

interface RequestItemsAction {
  type: "REQUEST_PROCUREMENT_ITEMS";
  projectId: number;
}

interface ReceiveItemsAction {
  type: "RECEIVE_PROCUREMENT_ITEMS";
  items: ProcurementItem[];
}

interface ReceiveQuotesAction {
  type: "RECEIVE_PROCUREMENT_QUOTES";
  quotes: ProcurementQuote[];
}

interface SelectedItemAction {
  type: "SELECTED_PROCUREMENT_ITEM_ACTION",
  id: number | null,
}

interface SelectedQuoteAction {
  type: "SELECTED_QUOTE_ACTION",
  id: number | null,
}

interface ToggleAddModal {
  type: 'TOGGLE_PROCUREMENT_ADD_MODAL'
}

interface ErrorAction {
  type: "PROCUREMENT_ERROR";
  message?: string;
}

type KnownAction =
  | RequestItemsAction
  | ReceiveItemsAction
  | ReceiveQuotesAction
  | SelectedItemAction
  | SelectedQuoteAction
  | ToggleAddModal
  | ErrorAction;

export const actionCreators = {
  getItems:
    (projectId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/items?projectId=${projectId}`)
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk)
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: data });
            else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
        dispatch({ type: "REQUEST_PROCUREMENT_ITEMS", projectId: projectId });
      },
  getItem:
    (procurementId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/ItemDetail?id=${procurementId}`)
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id == procurementId) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  getQuotes:
    (projectId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/quotes?projectId=${projectId}`)
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk)
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: data });
            else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
        dispatch({ type: "REQUEST_PROCUREMENT_ITEMS", projectId: projectId });
      },
  addItem: (model: any): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(
      `api/procurement/item?projectId=${getState().procurement.projectId}`, {
      method: "POST",
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(model)
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const newItems = getState().procurement.procurementItems.slice();
          newItems.push(data);
          dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
          dispatch({ type: 'TOGGLE_PROCUREMENT_ADD_MODAL' });
        } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
      });
  },
  addQuote:
    (model: any): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const formData = new FormData();
        if (model.fileList) {
          for (let i = 0; i < model.fileList.length; ++i) {
            formData.append('files', model.fileList[i], model.fileList[i].name)
          }
        }
        formData.append("json", JSON.stringify(model))
        fetch(
          `api/procurement/quote`,
          { method: "POST", body: formData }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newQuotes = getState().procurement.quotes.slice();
              newQuotes.unshift(data);
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
              //dispatch({ type: "SELECTED_QUOTE_ACTION", id: data.id });
              if (model.itemId) {
                const newItems = getState()
                  .procurement.procurementItems.slice()
                  .map((x) => {
                    if (x.id === model.itemId)
                      x.quoteSummaries.push({
                        id: data.id,
                        title: `${data.vendorName || ''}- ${data.leadTime || 'N/A'} - ${toMoney(data.price)}`,
                        approved: data.status === ProcurementQuoteStatus.Approved,
                      } as QuoteSummary);
                    return x;
                  });
                dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
                // @ts-ignore
                dispatch({ type: "UPDATE_MESSAGE_ACTION", message: "Saved" })
              }
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  addExistingQuote:
    (itemId: number, quoteId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(
          `api/procurement/existingquote?itemId=${itemId}&quoteId=${quoteId}`,
          { method: "POST" }
        )
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === itemId)
                    x.quoteSummaries.push({
                      id: data.id,
                      title: `${data.quoteNumber} - ${data.vendorName
                        } - ${toMoney(data.price)}`,
                      approved: data.status === ProcurementQuoteStatus.Approved,
                    } as QuoteSummary);
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  addComment:
    (itemId: number, comment: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/comment?itemId=${itemId}&comment=${comment}`, {
          method: "POST",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === itemId) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  updateItem:
    (
      id: number | string,
      field: string,
      value: any
    ): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/item?id=${id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ fieldName: field, value: value }),
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id == id) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  updateItemRfq: (itemId: number, rfq: RFQ): AppThunkAction<KnownAction> => (dispatch, getState) => {
    const newItems = getState().procurement.procurementItems.slice().map(x => {
      if (itemId === x.id) {
        x.rfq = rfq;
      }
      return x;
    })

    dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
  },
  updateQuote:
    (id: number, field: string, value: any): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/quote?id=${id}`, {
          method: "PUT",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ fieldName: field, value: value }),
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newQuotes = getState()
                .procurement.quotes.slice()
                .map((x) => {
                  if (x.id === id) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (
                    x.quoteSummaries &&
                    x.quoteSummaries.findIndex((y) => y.id === id) !== -1
                  ) {
                    const idx = x.quoteSummaries.findIndex((y) => y.id === id);
                    x.quoteSummaries[idx] = {
                      id: data.id,
                      title: `${data.vendorName || ''}- ${data.leadTime || 'N/A'} - ${toMoney(data.price)}`,
                      approved: data.status === ProcurementQuoteStatus.Approved,
                    } as QuoteSummary;
                  }
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  approveQuote:
    (id: number, itemId: number | null): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/approvequote?quoteId=${id}&itemId=${itemId}`, {
          method: "PUT",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newQuotes = getState()
                .procurement.quotes.slice()
                .map((x) => {
                  if (x.id === id) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
              // @ts-ignore
              const userId = getState().user.user.userId;
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (
                    x.quoteSummaries &&
                    x.quoteSummaries.findIndex((y) => y.id === id) !== -1
                  ) {
                    const idx = x.quoteSummaries.findIndex((y) => y.id === id);
                    const reviewerIdx = x.reviewers.findIndex(
                      (y) => y.userId === userId
                    );
                    x.quoteSummaries[idx] = {
                      id: data.id,
                      title: `${data.quoteNumber} - ${data.vendorName
                        } - ${toMoney(data.price)}`,
                      approved: data.status === ProcurementQuoteStatus.Approved,
                    } as QuoteSummary;
                    if (reviewerIdx !== -1)
                      x.reviewers[reviewerIdx].approvedQuoteId = id;
                  }
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  addReviewer:
    (id: number | string, userId: string): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/reviewer?itemId=${id}&userId=${userId}`, {
          method: "POST",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === id) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  removeReviewer:
    (itemId: number, reviewerId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/reviewer?reviewerId=${reviewerId}`, {
          method: "DELETE",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === itemId)
                    x.reviewers = x.reviewers.filter((r) => r.id !== reviewerId);
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  uploadItemAttachments:
    (itemId: number, files: FileList): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const form = new FormData();
        for (let i = 0; i < files.length; ++i)
          form.append("files", files[i], files[i].name);
        fetch(`api/procurement/itemattachments?id=${itemId}`, {
          method: "POST",
          body: form,
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === itemId) return data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  uploadQuoteAttachments:
    (quoteId: number, files: FileList): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        const form = new FormData();
        for (let i = 0; i < files.length; ++i)
          form.append("files", files[i], files[i].name);
        fetch(`api/procurement/QuoteAttachments?id=${quoteId}`, {
          method: "POST",
          body: form,
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newQuotes = getState()
                .procurement.quotes.slice()
                .map((x) => {
                  if (x.id === quoteId) x = data;
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  removeItemAttachment:
    (itemId: number, attachmentId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/itemattachment?attachmentId=${attachmentId}`, {
          method: "DELETE",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newItems = getState()
                .procurement.procurementItems.slice()
                .map((x) => {
                  if (x.id === itemId)
                    x.attachments = x.attachments.filter(
                      (a) => a.id !== attachmentId
                    );
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  removeQuoteAttachment:
    (quoteId: number, attachmentId: number): AppThunkAction<KnownAction> =>
      (dispatch, getState) => {
        fetch(`api/procurement/quoteattachment?attachmentId=${attachmentId}`, {
          method: "DELETE",
        })
          .then((res) => Promise.all([res.ok, res.json()]))
          .then(([resOk, data]) => {
            if (resOk) {
              const newQuotes = getState()
                .procurement.quotes.slice()
                .map((x) => {
                  if (x.id === quoteId)
                    x.attachments = x.attachments.filter(
                      (a) => a.id !== attachmentId
                    );
                  return x;
                });
              dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
            } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
          });
      },
  deleteQuote: (id: number) => (dispatch, getState) => {
    fetch(`api/procurement/quote?id=${id}`, { method: 'DELETE' })
      .then(res => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const newQuotes = getState()
            .procurement.quotes
            .slice()
            .filter((x: ProcurementQuote) => x.id !== id);
          const updatedItems = getState()
            .procurement.procurementItems
            .slice()
            .map((x: ProcurementItem) => {
              x.quoteSummaries = x.quoteSummaries.slice().filter(y => y.id !== id);
              //x.quotes = x.quotes.slice().filter(y => y.id !== id);
              return x;
            })
          dispatch({ type: "RECEIVE_PROCUREMENT_QUOTES", quotes: newQuotes });
          dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: updatedItems });
          dispatch({ type: "SELECTED_QUOTE_ACTION", id: null })
        }
      })
  },
  setSelectedQuote: (id: number | null) => (dispatch) => {
    dispatch({ type: "SELECTED_QUOTE_ACTION", id: id });
  },
  toggleAddModal: () => (dispatch) => {
    dispatch({ type: "TOGGLE_PROCUREMENT_ADD_MODAL" })
  },
  clearMessage: (): AppThunkAction<KnownAction> => (dispatch) => {
    dispatch({ type: "PROCUREMENT_ERROR", message: undefined });
  },
  requestPO: (itemId: number): AppThunkAction<KnownAction> => (dispatch, getState) => {
    fetch(`api/procurement/requestPO?itemId=${itemId}`, {
      method: "POST",
    })
      .then((res) => Promise.all([res.ok, res.json()]))
      .then(([resOk, data]) => {
        if (resOk) {
          const newItems = getState()
            .procurement.procurementItems.slice()
            .map((x) => {
              if (x.id === itemId) return data;
              return x;
            });
          dispatch({ type: "RECEIVE_PROCUREMENT_ITEMS", items: newItems });
        } else dispatch({ type: "PROCUREMENT_ERROR", message: data.message });
      });
  }
};
const unloadedStore: Store = {
  projectId: 0,
  showAddModal: false,
  procurementItems: [],
  quotes: [],
  selectedItemId: null,
  selectedQuoteId: null,
  loading: false,
};

//@ts-ignore
export const reducer: Reducer<Store> = (
  state: Store,
  incomingAction: Action
) => {
  const action = incomingAction as KnownAction;
  switch (action.type) {
    case "REQUEST_PROCUREMENT_ITEMS":
      return { ...state, projectId: action.projectId, loading: true };
    case "RECEIVE_PROCUREMENT_ITEMS":
      return { ...state, procurementItems: action.items, loading: false };
    case "RECEIVE_PROCUREMENT_QUOTES":
      return { ...state, quotes: action.quotes, loading: false };
    case "SELECTED_PROCUREMENT_ITEM_ACTION":
      return { ...state, selectedQuoteId: action.id }
    case "SELECTED_QUOTE_ACTION":
      return { ...state, selectedQuoteId: action.id }
    case "TOGGLE_PROCUREMENT_ADD_MODAL":
      return { ...state, showAddModal: !state.showAddModal }
    case "PROCUREMENT_ERROR":
      return { ...state, message: action.message, loading: false };
    default: {
      const exhaustiveCheck: never = action;
    }
  }
  return state || unloadedStore;
};
